import { QosProvider } from '@iamexperiences/ecos-telemetry';
import * as React from 'react';
import { InfinityListV2 } from '../../Shared/InfinityList';
import { CheckboxVisibility, css, SelectionMode } from '@fluentui/react';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import { LocaleKeys, GetGrantFromEntitlement, GetGrantRequestFromGrant, getPageMetaDataFromEntities } from '../../../shared';
import { useGetRecommendations, usePartialGrantRequest, useSearchBox, useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { IAccessPackageRecommendation, RequestType, EntityType, IEntitlement, LoadMode, TelemetryEvent, RequestSuggestionType, AccessPackageSuggestionReasonType } from '../../../models';
import { getEntitlementRecommendationListColumns } from './EntitlementRecommendationList.columns';
import { ConnectedRequestGrantModal } from '../RequestGrantPanel';
import { NAVIGATION_LINKS } from '../../../shared/constants';
import { history } from '../../../redux/configureStore';
import { getEntitlementUrl } from '../../../shared/getEntitlementUrl';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');


export function EntitlementRecommendationList(): JSX.Element{

  const { suggestions, loadMode, suggestionList, refreshRecommendations, getRecommendations} = useGetRecommendations();
  const { partialGrantRequest, setPartialGrantRequest } = usePartialGrantRequest();

  const responsiveMode = useResponsiveMode();
  const t = useTranslation();
  const {showSearchBox} = useSearchBox();
  const telemetry = useTelemetryWithMetadata();

  const [selectedEntitlement, setSelectedEntitlement] = React.useState({} as IEntitlement);
  const [selectedRecommendationReason, setSelectedRecommendationReason] = React.useState(RequestSuggestionType.None);
  const [showLoadMore, setShowLoadMore] = React.useState(false);
  const [showNoEntities, setShowNoEntities] = React.useState(false);

  React.useEffect(() => {
    refreshRecommendations({entityType: EntityType.suggestions});
  }, []);

  React.useEffect(() => {
    if (suggestions){
      const pageMetaData = getPageMetaDataFromEntities(suggestions);
      setShowNoEntities(pageMetaData.allEntityCount === 0);
      setShowLoadMore(!pageMetaData.isAllEntitiesFullyCached && !suggestions.isLoading && loadMode !== LoadMode.LoadMore && !pageMetaData.isFilteredEntitiesFullyCached);
    }
  }, [refreshRecommendations, loadMode]);

  React.useEffect(() => {
    showSearchBox(false);
    return () => {
      showSearchBox(true);
    };
  },[]);

  const onCtaButtonClicked = (item: IAccessPackageRecommendation): void => {
    const entitlement = (item as IAccessPackageRecommendation).accessPackage;
    const grant = GetGrantFromEntitlement(entitlement);
    const grantRequest = GetGrantRequestFromGrant(grant!, RequestType.UserAdd);
    setSelectedEntitlement(entitlement!);
    setPartialGrantRequest(grantRequest!);
    setSelectedRecommendationReason(getRecommendationReason(item));
    telemetry.reportCustomEventWithMetadata(TelemetryEvent.Recommendation_List_Request, {accessPackageId: entitlement?.id, suggestionReason: selectedRecommendationReason});
  };

  const columns = getEntitlementRecommendationListColumns(responsiveMode, t, false, true, onCtaButtonClicked);

  const onRowClicked = (item: IAccessPackageRecommendation): void => {
    onCtaButtonClicked(item);
  };

  const dismiss2022GrantRequest = (): void => {
    setPartialGrantRequest({});
  }

  const dismissEntitlementDetailsPanel = () : void => {
    return;
  }

  const loadMore =(): void => {
    if (!suggestions.isLoading){
      getRecommendations({entityType: EntityType.suggestions});
    }
  }

  const reportEventOnSubmission = (): void => {
    telemetry.reportCustomEventWithMetadata(TelemetryEvent.Recommendation_List_Request_Submit, {source: 'suggestions', reason: selectedRecommendationReason});
  };

  const getRecommendationReason = (item: IAccessPackageRecommendation): RequestSuggestionType  => {
    let findRelatedPeopleReason: boolean = false;
    let findAssignmentHisotryReason: boolean = false;
    if (item.accessPackageSuggestionReasons &&
      item.accessPackageSuggestionReasons?.findIndex(r => r["@odata.type"] === AccessPackageSuggestionReasonType.AccessPackageSuggestionRelatedPeopleBased) >= 0) {
        findRelatedPeopleReason = true;
    }
    if (item.accessPackageSuggestionReasons &&
      item.accessPackageSuggestionReasons?.findIndex(r => r["@odata.type"] === AccessPackageSuggestionReasonType.AccessPackageSuggestionSelfAssignmentHistoryBased) >= 0){
        findAssignmentHisotryReason = true;
    }

    if (findRelatedPeopleReason && findAssignmentHisotryReason) return RequestSuggestionType.Both;
    if (findRelatedPeopleReason) return RequestSuggestionType.RelatedPeRequestSuggestionType;
    if (findAssignmentHisotryReason) return RequestSuggestionType.AssignmentHistory;
    return RequestSuggestionType.None;
  }

  return (
  <QosProvider name="EntitlementRecommendationList">
    <div
        className={css(
          myAccessListStyles.listPage,
          myAccessListStyles.paddingSmallTop,
          myAccessListStyles.listWithHoverRow
        )}
      >
          <InfinityListV2
          t={t}
          entityList={suggestionList}
          ariaLabel={'List of access packages recommendations.'}
          columns={columns}
          showLoadMore={showLoadMore}
          showSpinner={!suggestions || suggestions.isLoading || suggestions.loadMode === LoadMode.LoadMore}
          spinnerLabel={t(LocaleKeys.loadingPage, {
            pageName: t(LocaleKeys.recommendation, { context: 'plural' }),
          })}
          showNoEntities={showNoEntities}
          noEntitiesProps={{
            noRowMessage: LocaleKeys.noRecommendedAccessPackageMessage,
            noRowSubMessage: LocaleKeys.noRecommendedAccessPackageSubMessage,
            showButton: false,
            showLink: true,
            imageSource: '/imgs/Illustration.svg',
            linkText: t(LocaleKeys.viewAllAvailable),
            onButtonClick: () =>
              {
                telemetry.reportCustomEventWithMetadata(TelemetryEvent.Recommendation_View_All_Available_Action_Button);
                history.push(NAVIGATION_LINKS.accessPackages.subRoutePaths!.available.link);
              }
          }}
          showNoFilteredResults={false}
          onLoadMore={loadMore}
          onRowClicked={onRowClicked}
          selectionMode={SelectionMode.none}
          isExpanded={false}
          searchTerm={''}
          selectedFilterKey={''}
          checkboxVisibility={CheckboxVisibility.hidden}
          renderFooter={!showLoadMore && suggestions && !suggestions.isLoading && !showNoEntities}
          skipScrollHandling={true}
          />
          {partialGrantRequest?.id !== undefined && selectedEntitlement? (
            <ConnectedRequestGrantModal
            entitlementName={selectedEntitlement.displayName}
            entitlementDescription={selectedEntitlement.description}
            entitlementResources={selectedEntitlement.accessPackageResourceRoleScopes}
            entitlementUrl={getEntitlementUrl(selectedEntitlement.id)}
            canViewResources={selectedEntitlement.isRoleScopesVisible}
            isResourceLoaded={false}
            onSubmitRequest={reportEventOnSubmission}
            onDismiss={() => {
              dismiss2022GrantRequest();
              dismissEntitlementDetailsPanel();
            }}
          />) : null}
        </div>
  </QosProvider>
  );
}