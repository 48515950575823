import { IContextualMenuItem } from '@fluentui/react';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { isInternalUser } from '../../../src/shared';
import { EntitlementActions } from '../../models';
import { EntityType } from '../../models/EntityType';
import { IRootEntitlementsState } from '../../models/IEntitlementState';
import { createTelemetryAction, GetPeopleRequestType, IGetPhotoActionPayload } from '../../redux';
import { IsRenewAccessVisible } from '../../shared/IsRenewAccessVisible';
import { Routes } from '../../shared/Routes';
import { MyAccessMain } from './MyAccessMain';
import { IConnectedMyAccessMainProps, IMyAccessMainActions, IMyAccessMainMappedProps } from './MyAccessMain.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the Entitlement control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IEntitlementProps) for the Entitlement control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedMyAccessMainProps
): IMyAccessMainMappedProps => {
  const {
    tenantContext,
    showFiltersIcon,
    pageCounts,
    filterItems,
    searchBoxVisible,
    routes,
    directReports,
    usersPhoto
  } = state.app;
  const organizations = state.user.organizations;
  const organizationsById = state.user.organizationsById;

  const tenantPickerOptions = organizations
    ? organizations.map((tid: string) => {
        return {
          key: tid,
          text: organizationsById ? organizationsById.get(tid)!.displayName : ''
        };
      })
    : [];

  const isGuestUser = !isInternalUser();

  const isUserInTenant = isInternalUser();

  const showAccessPackageExpiringIndicator = Array.from(state.app.validGrants.entitiesById.values?.() ?? []).some((grant) =>
    IsRenewAccessVisible(grant.schedule.stopDateTime! as Date)
  );

  // Search by resource name filter should be hidden in expired pivot and for guest users.
  const hideResourceSearchFilterOption = isGuestUser || location.hash.includes(Routes.previousAccessTab);
  return {
    ..._ownProps,
    tenantContext,
    tenantPickerOptions,
    showFiltersIcon,
    hideResourceSearchFilterOption,
    pageCounts,
    filterItems,
    searchBoxVisible,
    isUserInTenant,
    showAccessPackageExpiringIndicator,
    features: state.app.features.isEnabled,
    routes,
    user: state.user,
    enableNewRequestForm: state.app.features.isEnabled.enableNewRequestForm,
    loadableDirectReports: directReports,
    usersPhoto
  };
};

/**
 * Maps the dispatch actions to the props for the Entitlement control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IEntitlementProps) for the Entitlement control
 */
const mapDispatchToProps = (dispatch: Dispatch<IRootEntitlementsState>): IMyAccessMainActions => {
  const getPeople = createAction(EntitlementActions.getPeople, dispatch);
  return {
    getPendingApprovalsCount: (isElmApprovalApiEnabled: boolean) =>
      createAction(
        isElmApprovalApiEnabled
          ? EntitlementActions.getPendingApprovalGrantRequestsCount
          : EntitlementActions.getPendingApprovalsCount,
        dispatch
      )(),
    updateFilterItems: createAction<IContextualMenuItem[]>(EntitlementActions.updateFilterItems, dispatch),
    showSearch: createAction<boolean>(EntitlementActions.showSearchBox, dispatch),
    getValidGrants: () =>
      createAction<{ entityType: string }>(
        EntitlementActions.getEntities,
        dispatch
      )({
        entityType: EntityType.validGrants
      }),
    recordBusinessEvent: (eventName) => dispatch(createTelemetryAction(eventName)),
    getValidGrantsCount: () => createAction(EntitlementActions.getActiveGrantsCount, dispatch)(),
    getExpiredGrantsCount: () => createAction(EntitlementActions.getExpiredGrantsCount, dispatch)(),
    getEntitlementsCount: () => createAction(EntitlementActions.getEntitlementsCount, dispatch)(),
    refreshFeatures: () => createAction(EntitlementActions.refreshAdminOptInFeatures, dispatch)(),
    getDirectReports: () => getPeople({ requestType: GetPeopleRequestType.directReports }),
    getPhoto: createAction<IGetPhotoActionPayload>(EntitlementActions.getPhoto, dispatch)
  };
};

/**
 * A redux connected Entitlement control.
 * @description Supports translation
 */
export const ConnectedMyAccessMain = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(MyAccessMain as any) as any);
