import { withAuth } from '@microsoft/portal-app/lib/auth/withAuth';
import { patchStore, Store } from '@microsoft/portal-app/lib/redux/compat';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { ajax } from 'rxjs/observable/dom/ajax';

import { getRootEpic, IGlobalState, rootReducer } from './modules/root';

const createReduxWaitForMiddleware = require('redux-wait-for-action').default;

const withAuthAjax = withAuth(ajax);
export const history = createHashHistory();
const routerMiddlewareToApply = routerMiddleware(history);

export const configureStore = (): Store<IGlobalState> => {
  const epicMiddleware = createEpicMiddleware(getRootEpic(), {
    dependencies: { ajax: withAuthAjax }
  });

  const middleware = applyMiddleware(
    epicMiddleware,
    routerMiddlewareToApply,
    createReduxWaitForMiddleware(),
  );

  const enhancer = process.env.NODE_ENV === 'development'
    ? composeWithDevTools(middleware)
    : compose(middleware);

  const store = patchStore(
    createStore<IGlobalState>(
      rootReducer(history),
      enhancer
    )
  );

  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('./modules/root', async () => {
      const root = await import('./modules/root');
      store.replaceReducer(root.rootReducer(history));
    });
  }

  return store;
};
