import { TranslationFunction } from 'i18next';
import {
  CheckboxVisibility,
  css,
  DefaultButton,
  DetailsList,
  DetailsRow,
  getTheme,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IRenderFunction,
  ScrollablePane,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Sticky
} from '@fluentui/react';
import * as React from 'react';

import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IEntity } from '../../../models/ELM/IEntity';
import { IGrant } from '../../../models/ELM/IGrant';
import { IRoleAssignment } from '../../../models/ELM/IRoleAssignment';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ConnectedEntitlementCard } from '../../ELM/EntitlementCard/ConnectedEntitlementCard';
import { INoEntitiesProps, NoEntities } from '../NoEntities/NoEntities';
import { NoFilteredResults } from '../NoFilteredResults/NoFilteredResults';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

export interface IInfinityListProps {
  entityList: IEntity[];
  entityType?: EntityType;
  ariaLabel: string;
  columns: IListColumn<IEntity>[];
  showLoadMore: boolean;
  showSpinner: boolean;
  spinnerLabel: string;
  showNoEntities: boolean;
  noEntitiesProps?: INoEntitiesProps;
  showNoFilteredResults: boolean;
  selectionMode: SelectionMode;
  isExpanded: boolean;
  searchTerm?: string | null;
  selectedFilterKey?: string | null;
  clearSelection?: boolean;
  selectAll?: boolean;
  onClearSelection?: () => void;
  checkboxVisibility?: CheckboxVisibility;
  showColumnHeaders?: boolean;
  t: TranslationFunction;
  onLoadMore(): void;
  onItemSelected?(selectedItems: IEntity[]): void;
  onRowClicked?(item: IEntity): void;
  onSetHoveredKey?(key: string | null): void;
  onRender?(item: IEntity, detailsRowProps: IDetailsRowProps): JSX.Element;
  renderFooter?: boolean;
  skipScrollHandling?: boolean;
}

export class InfinityList extends React.Component<IInfinityListProps> {
  private _selection: Selection;
  constructor(nextProps: IInfinityListProps) {
    super(nextProps);
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.props.onItemSelected && this.props.onItemSelected(this.getSelectedItems());
      }
    });
  }

  public componentDidUpdate(prevProps: IInfinityListProps): void {
    if (this.props.clearSelection && !prevProps.clearSelection) {
      this._selection.setAllSelected(false);
      if (this.props.onClearSelection) {
        this.props.onClearSelection();
      }
    }
    if (this.props.selectAll && !prevProps.selectAll) {
      this._selection.setAllSelected(true);
    }
  }

  public render(): JSX.Element {
    const {
      entityList,
      ariaLabel,
      showLoadMore,
      showSpinner,
      spinnerLabel,
      columns,
      onLoadMore,
      showNoEntities,
      showNoFilteredResults,
      noEntitiesProps,
      selectionMode,
      showColumnHeaders,
      t,
      checkboxVisibility,
    } = this.props;

    const list = (
      <DetailsList
        className={css(myAccessStyles.marginBottomSmall, myAccessListStyles.list)}
        ariaLabel={ariaLabel}
        isHeaderVisible={showColumnHeaders!}
        checkboxVisibility={checkboxVisibility || CheckboxVisibility.always}
        columns={columns}
        items={entityList}
        setKey="id"
        onRenderDetailsHeader={
          // tslint:disable-next-line:jsx-no-lambda
          (detailsHeaderProps?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => (
            <Sticky stickyClassName={css(myAccessListStyles.list)}>
              <div className={css(myAccessListStyles.listColumnHeader)}>{defaultRender!(detailsHeaderProps)}</div>
            </Sticky>
          )
        }
        onRenderRow={this.onRenderRow}
        selectionMode={selectionMode}
        selection={this._selection}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForListHeader={'Column headers. User menus to perform column operations like sort and filter'}
        ariaLabelForSelectionColumn={'Toggle selection'}
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="select row"
      />
    );

    const richList = (
      <div
        // tslint:disable-next-line:jsx-ban-props
        style={{ overflowX: 'hidden' }}
        // tslint:disable-next-line:jsx-no-lambda
        onMouseLeave={() => (this.props.onSetHoveredKey ? this.props.onSetHoveredKey(null) : null)}
      >
        {list}
        {showLoadMore ? (
          <div className={css(myAccessStyles.alignCenter)}>
            <DefaultButton onClick={onLoadMore} text={t(LocaleKeys.loadMore)} />
          </div>
        ) : null}
        {showSpinner ? <Spinner size={SpinnerSize.medium} label={spinnerLabel} /> : null}
        {showNoEntities && noEntitiesProps ? (
          <NoEntities
            iconName={noEntitiesProps.iconName}
            noRowMessage={t(noEntitiesProps.noRowMessage)}
            showButton={noEntitiesProps.showButton}
            buttonText={t(noEntitiesProps.buttonText!, {
              context: 'capitalize'
            })}
            onButtonClick={noEntitiesProps.onButtonClick}
            linkText={noEntitiesProps.linkText}
            imageSource={noEntitiesProps.imageSource}
            showLink={noEntitiesProps.showLink}
            noRowSubMessage={t(noEntitiesProps.noRowSubMessage!)}
          />
        ) : null}
        {showNoFilteredResults ? <NoFilteredResults t={t} /> : null}
      </div>
    );

    return (
      <div
        onScroll={this.props.skipScrollHandling === true? ()=>{} : this.handleScroll}
        id="entityList"
        role="region"
        aria-labelledby="entityList"
        // tslint:disable-next-line:jsx-ban-props
        style={{
          position: 'relative',
          height: '100%',
          maxHeight: 'inherit',
          minHeight: '416px'
        }}
      >
        <ScrollablePane
          styles={{
            stickyBelow: myAccessListStyles.stickybelowitems
          }}
        >
          {richList}
        </ScrollablePane>
      </div>
    );
  }

  private onRenderRow = (props: IDetailsRowProps): JSX.Element => {
    if (this.props.onRender) {
      return this.props.onRender(props.item, props);
    }

    let entitlement: IEntitlement | null = null;
    let accessPackageResourceRoleScope: IRoleAssignment[] = [];
    let canShowResources: boolean = true;
    let canOpenResources: Map<string, boolean>;
    let errorMessage: string | null = null;
    let isExpanded: boolean = false;
    let isDelivering: boolean = false;

    switch (this.props.entityType) {
      case EntityType.entitlements:
        entitlement = this.props.entityList[props.itemIndex] as IEntitlement;
        accessPackageResourceRoleScope =
          entitlement && (entitlement.accessPackageResourceRoleScopes! as IRoleAssignment[]);
        canShowResources = entitlement && entitlement.isRoleScopesVisible!;
        canOpenResources = new Map(accessPackageResourceRoleScope.map((rs: IRoleAssignment) => [rs.id, false]));
        isExpanded = this.props.isExpanded;
        if (!canShowResources) {
          errorMessage = LocaleKeys.permissionsNotVisibleMessage;
        }
        break;

      case EntityType.validGrants:
        const validGrant = this.props.entityList[props.itemIndex] as IGrant;
        entitlement = validGrant.accessPackage;
        accessPackageResourceRoleScope = validGrant && validGrant.accessPackageAssignmentResourceRoles!;
        canOpenResources = new Map(
          accessPackageResourceRoleScope.map((rs: IRoleAssignment) => [rs.id, (rs.status as string) === 'Fulfilled'])
        );
        isDelivering = validGrant.assignmentState === 'Delivering';
        break;

      case EntityType.expiredGrants:
        const expiredGrant = this.props.entityList[props.itemIndex] as IGrant;
        entitlement = expiredGrant.accessPackage;
        accessPackageResourceRoleScope = [];
        canShowResources = false;
        canOpenResources = new Map(accessPackageResourceRoleScope.map((rs: IRoleAssignment) => [rs.id, false]));
        errorMessage = LocaleKeys.expiredResourcesMessage;
        break;

      case EntityType.virtualMachines:
        const customStyles: Partial<IDetailsRowStyles> = {};
        const theme = getTheme();

        if (props.itemIndex % 2 === 0) {
          customStyles.root = { backgroundColor: theme.palette.neutralLighterAlt };
        }
        return <DetailsRow {...props} className={myAccessListStyles.listRow} styles={customStyles} />;

      // return the default render from detailsRow of DetailsList
      default:
        const detailsRowProps = {
          ...props,
          className: myAccessListStyles.listRow,
          onClick: () => this.props.onRowClicked && this.props.onRowClicked(props.item)
        };
        return <DetailsRow {...detailsRowProps} />;
    }

    return (
      <div
        // tslint:disable-next-line:jsx-no-lambda
        onMouseEnter={() =>
          this.props.onSetHoveredKey ? this.props.onSetHoveredKey(entitlement && entitlement.id) : null
        }
      >
        <ConnectedEntitlementCard
          key={entitlement.id}
          t={this.props.t}
          detailsRowProps={props}
          entitlement={entitlement!}
          accessPackageResourceRoleScopes={accessPackageResourceRoleScope}
          canShowResources={canShowResources}
          canOpenResources={canOpenResources}
          errorMessage={errorMessage}
          isExpandedByDefault={isExpanded}
          searchTerm={this.props.searchTerm}
          selectedFilterKey={this.props.selectedFilterKey}
          isExpandedBasedOnSearchFilter={this.getIsExpandedBasedOnSearchFilter()}
          isDelivering={isDelivering}
        />
      </div>
    );
  };

  private handleScroll = (): void => {
    const elDom = document.getElementById('entityList');
    if (elDom!.offsetHeight + elDom!.scrollTop === elDom!.scrollHeight) {
      this.props.onLoadMore();
    }
  };

  private getSelectedItems = (): IEntity[] => {
    const selectedItems = this._selection.getSelection() as IEntity[];
    return selectedItems;
  };

  private getIsExpandedBasedOnSearchFilter = (): boolean => {
    if (isEmptyOrUndefined(this.props.searchTerm)) {
      return false;
    }
    switch (this.props.selectedFilterKey) {
      case EntitlementSearchFilter.All:
      case EntitlementSearchFilter.Resources:
        return true;
      case EntitlementSearchFilter.AccessPackage:
        return false;
      default:
        return false;
    }
  };
}
