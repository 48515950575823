import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';

import {
  ActionButton,
  ColorClassNames,
  css,
  DefaultButton,
  Dialog,
  DialogFooter,
  FontClassNames,
  IButtonStyles,
  Icon,
IIconProps,  Link,
  PrimaryButton,
  TextField
 } from '@fluentui/react';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import { TranslationFunction } from 'i18next';
import * as React from 'react';
import { isNullOrUndefined } from 'util';

import { AccessReviewType } from '../../../models/AccessReviews/AccessReviewType';
import { IDecisionsSummary } from '../../../models/AccessReviews/IDecisionsSummary';
import { ISubmitDecision } from '../../../models/AccessReviews/ISubmitDecision';
import { IFilter } from '../../../models/IFilter';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export const BackButtonStyles: IButtonStyles = {
  root: {
    fontWeight: 600,
  }
};

export enum DialogType {
  Override = 'Override',
  Review = 'Review',
  Recommendation = 'Recommendation',
  Justification = 'Justification'
}

export interface IReviewDecisionsDialogProps {
  decisionSummary: IDecisionsSummary | undefined;
  decisionSubjectType: string | undefined;
  currFilter: IFilter | null;
  filteredEntityCount: number;
  reviewId: string | undefined;
  resourceName: string | undefined;
  resourceType: string | undefined;
  isSubmitting: boolean;
  submittingText?: string | ILocalizableMessage;
  t: TranslationFunction;
  responsiveMode?: ResponsiveMode;
  submitAllDecisions: (decision: ISubmitDecision) => void;
  onDismiss: () => void;
}

export interface IReviewDecisionsDialogState {
  dialogType: DialogType;
  decisionFilter: IFilter;
  justification: string;
  decisionType: string;
}

export class ReviewDecisionsDialog extends React.Component<IReviewDecisionsDialogProps, IReviewDecisionsDialogState> {
  constructor(nextProps: IReviewDecisionsDialogProps) {
    super(nextProps);
    this.state = {
      dialogType: DialogType.Override,
      decisionFilter: {},
      justification: '',
      decisionType: ''
    };
  }

  private readonly _closeDialog = (): void => {
    this.props.onDismiss();
  };

  public render(): JSX.Element {
    const {
      decisionSummary,
      decisionSubjectType,
      currFilter,
      filteredEntityCount,
      reviewId,
      resourceName,
      isSubmitting,
      submittingText,
      responsiveMode,
      t
    } = this.props;

    let totalCount = 0;
    let notReviewedCount = 0;
    if (!decisionSummary) {
      // only check count when decision summary call returns
      return (
        <Dialog
          hidden={false}
          isOpen={true}
          onDismiss={this.props.onDismiss}
          modalProps={{
            containerClassName:
              responsiveMode! < ResponsiveMode.large
                ? ''
                : myAccessStyles.wideDialog
          }}
        >
          <DialogFooter>
            {
              getSpinner(asLocalizedText(submittingText || LocaleKeys.loading, t))
            }
          </DialogFooter>
        </Dialog>
      );
    } else {
      totalCount = (this.props.currFilter !== null) ? this.props.filteredEntityCount : decisionSummary.totalCount;
      notReviewedCount = (this.props.currFilter !== null &&
        !this.props.currFilter.selectedDecisions?.includes(DecisionType.NotReviewed))
        ? 0 // If no pre-filter on NotReviewed then set notReviewedCount = 0
        : decisionSummary.notReviewedCount;
    }

    const filteredOnlyByNotReviewed = (this.props.currFilter !== null && this.props.currFilter.selectedDecisions?.length === 1 &&
      this.props.currFilter.selectedDecisions?.includes(DecisionType.NotReviewed));

    if (this.state.dialogType === DialogType.Override) {
      return this._getOverrideDialog(
        this.props.isSubmitting,
        this.props.submittingText,
        t,
        this.props.responsiveMode,
        totalCount,
        notReviewedCount,
        filteredOnlyByNotReviewed);
    } else if (this.state.dialogType === DialogType.Review) {
      return this._getReviewDialog(
        this.props.isSubmitting,
        this.props.submittingText,
        t,
        this.props.responsiveMode,
        totalCount,
        notReviewedCount,
        filteredOnlyByNotReviewed);
    } else if (this.state.dialogType === DialogType.Justification) {
      return this._getJustificationDialog(
        this.props.isSubmitting,
        this.props.submittingText,
        t,
        this.props.responsiveMode,
        totalCount,
        notReviewedCount);
    }
    // Open recommendation dialog
    return this._getRecommendationDialog(
      this.props.isSubmitting,
      this.props.submittingText,
      t,
      this.props.responsiveMode,
      totalCount,
      notReviewedCount);
  }

  private _checkDialogStatus(totalCount: number, notReviewedCount: number, filteredOnlyByNotReviewed: boolean): void {
    // If filtered by not reviewed, skip override vs keep question and retain original filter
    if (filteredOnlyByNotReviewed) {
      this.setState({
        dialogType: DialogType.Review,
        decisionFilter: this.props.currFilter
      });
    }

    // If all (not) reviewed, skip override vs keep question and override. Filter on all type of result
    if (notReviewedCount === 0 || notReviewedCount === totalCount) {
      const filter = {
        selectedDecisions: (this.props.currFilter !== null) ? this.props.currFilter.selectedDecisions! :
          [DecisionType.NotReviewed, DecisionType.Approve, DecisionType.Deny, DecisionType.DontKnow],
        selectedRecommendations: (this.props.currFilter !== null) ? this.props.currFilter.selectedRecommendations! : []
      };

      this.setState({
        dialogType: DialogType.Review,
        decisionFilter: filter
      });
    }
  }

  private readonly _getOverrideDialog = (
    isSubmitting: boolean,
    submittingText?: string | ILocalizableMessage,
    t: TranslationFunction,
    responsiveMode?: ResponsiveMode,
    totalCount: number,
    notReviewedCount: number,
    filteredOnlyByNotReviewed: boolean
  ): JSX.Element => {
    this._checkDialogStatus(totalCount, notReviewedCount, filteredOnlyByNotReviewed);
    const title = LocaleKeys.accessReviewDecisionSelectAllTitle;
    // label Type maybe user. group, principals, or approval
    // TODO: change to real approval type
    const labelType = LocaleKeys.accessReviewApproval;
    const overriddenDecisionCount = this._overriddenDecisionCount(totalCount, notReviewedCount);

    return (
      <Dialog
        hidden={false}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        title={t(title, { decisionSubjectType: this.props.decisionSubjectType, resourceName: this.props.resourceName })}
        modalProps={{
          containerClassName:
            responsiveMode! < ResponsiveMode.large
              ? ''
              : myAccessStyles.wideDialog
        }}
      >
        {!isSubmitting ? (
          <div>
            <div className={css(myAccessStyles.marginBottomSmall, myAccessStyles.bold)}>
              {asLocalizedText(
                {
                  key: LocaleKeys.entityNameWithReviewedCount,
                  options: {
                    count: totalCount,
                    reviewedCount: overriddenDecisionCount,
                    entityName: t(labelType, { count: totalCount })
                  }
                },
                t
              )}
            </div>
            <div className={css(myAccessStyles.marginBottomMedium)}>
              {t(LocaleKeys.allApprovalSelectedOverride)}
            </div>
            <div
              className={css(
                myAccessStyles.marginTopSmall,
                myAccessStyles.marginBottomSmall,
                myAccessStyles.paddingSmallPlus,
                ColorClassNames.neutralLighterBackground,
                myAccessStyles.semiBoldText)}
            >
              {t(LocaleKeys.decisionOverrideConfirmation)}
            </div>
          </div>
        ) : null}
        <DialogFooter>
          {isSubmitting ? (
            getSpinner(asLocalizedText(submittingText || LocaleKeys.loading, t))
          ) : (
            <div>
              <DefaultButton
                // Override apply review result to all approval
                onClick={() => this._switchToReviewDialog(this._getFilter(
                  // When override, pass existing decision and recommendation filter
                  (this.props.currFilter !== null) ? this.props.currFilter.selectedDecisions!
                    : [DecisionType.NotReviewed,
                    DecisionType.Approve,
                    DecisionType.Deny,
                    DecisionType.DontKnow],
                  (this.props.currFilter !== null) ? this.props.currFilter.selectedRecommendations! : []))}
                text={t(LocaleKeys.override)}
                className={css(myAccessStyles.marginRightSmall)}
              />
              <PrimaryButton
                onClick={() => this._switchToReviewDialog(this._getFilter([DecisionType.NotReviewed],
                  // When keep existing, only pass recommendation filter in addition to notReviewed
                  (this.props.currFilter !== null) ? this.props.currFilter.selectedRecommendations! : []))}
                text={t(LocaleKeys.keepexisting)}
                className={css(myAccessStyles.marginRightSmall)}
              />
            </div>
          )}
        </DialogFooter>
      </Dialog>
    );
  }

  private readonly _getReviewDialog = (
    isSubmitting: boolean,
    submittingText?: string | ILocalizableMessage,
    t: TranslationFunction,
    responsiveMode?: ResponsiveMode,
    totalCount: number,
    notReviewedCount: number,
    filteredOnlyByNotReviewed: boolean
  ): JSX.Element => {
    const title = isNullOrUndefined(this.props.resourceName) || this.props.resourceName === ''
      ? LocaleKeys.shouldContinueAccessByod
      : LocaleKeys.shouldContinueAccess;
    // label Type maybe user. group, principals, or approval
    // TODO: change to real approval type
    const labelType = LocaleKeys.accessReviewApproval;
    let warningMessage = LocaleKeys.notReviewedApprovalSelected;
    let approvalSelectedMessage = LocaleKeys.approvalSelected;

    // If filtered by notReviewed only display notReviewed approvals
    if (this.state.decisionFilter.selectedDecisions?.length === 1
      && this.state.decisionFilter.selectedDecisions[0] === DecisionType.NotReviewed) {
      approvalSelectedMessage = notReviewedCount <= 1 ? t(LocaleKeys.approvalSelected, { count: notReviewedCount })
        : t(LocaleKeys.approvalSelected, { count: notReviewedCount, context: 'plural' });
      warningMessage = t(LocaleKeys.notReviewedApprovalSelected);
    } else {
      approvalSelectedMessage = totalCount <= 1 ? t(LocaleKeys.approvalSelected, { count: totalCount })
        : t(LocaleKeys.approvalSelected, { count: totalCount, context: 'plural' });
      warningMessage = t(LocaleKeys.allApprovalSelectedOverride);
    }

    if (notReviewedCount === totalCount) {
      warningMessage = t(LocaleKeys.accessReviewDecisionSelectAll);
    }

    return (
      <Dialog
        hidden={false}
        isOpen={true}
        onDismiss={this._closeDialog}
        title={t(title, { decisionSubjectType: this.props.decisionSubjectType, resourceName: this.props.resourceName })}
        modalProps={{
          containerClassName:
            responsiveMode! < ResponsiveMode.large
              ? ''
              : myAccessStyles.wideDialog
        }}
      >
        {!isSubmitting ? (
          <div>
            <div className={css(myAccessStyles.marginBottomXSmall, myAccessStyles.bold)}>
              {t(approvalSelectedMessage)}
            </div>
            <div className={css(myAccessStyles.marginBottomMedium)}>
              <Icon
                iconName={'Info'}
                className={css(
                  myAccessStyles.warningColor,
                  myAccessStyles.smallIconAlign,
                  myAccessStyles.marginRightSmall
                )}
              />
              {t(warningMessage)}
            </div>
            {
              <div
                className={css(
                  myAccessStyles.marginTopSmall,
                  myAccessStyles.marginBottomSmall,
                  myAccessStyles.paddingSmallPlus,
                  ColorClassNames.neutralLighterBackground,
                  myAccessStyles.semiBold
                )}
              >
                {t(LocaleKeys.acceptAllRecommendationsRedirect)}
                <Link
                  onClick={() => this._switchToRecommendationDialog(this.state.decisionFilter)}
                  className={css(FontClassNames.medium, myAccessStyles.themeDarkFont)}
                >
                  {t(LocaleKeys.acceptRecommendations)}
                </Link>
              </div>
            }
          </div>
        ) : null}
        <DialogFooter>
          {isSubmitting ? (
            getSpinner(asLocalizedText(submittingText || LocaleKeys.loading, t))
          ) : (
            <div>
              <DefaultButton
                onClick={() => this._switchToJustificationDialog(DecisionType.Approve)}
                text={t(LocaleKeys.approve)}
                className={css(myAccessStyles.marginRightSmall)}
              />
              <DefaultButton
                onClick={() => this._switchToJustificationDialog(DecisionType.Deny)}
                text={t(LocaleKeys.deny)}
                className={css(myAccessStyles.marginRightSmall)}
              />
              <DefaultButton
                onClick={() => this._switchToJustificationDialog(DecisionType.DontKnow)}
                text={t(LocaleKeys.dontKnow)}
                className={css(myAccessStyles.marginRightSmall)}
              />
              <ActionButton
                onClick={this._closeDialog}
                text={t(LocaleKeys.back)}
                styles={BackButtonStyles}
              />
            </div>
          )}
        </DialogFooter>
      </Dialog>
    );
  }

  private readonly _getJustificationDialog = (
    isSubmitting: boolean,
    submittingText?: string | ILocalizableMessage,
    t: TranslationFunction,
    responsiveMode?: ResponsiveMode,
    totalCount: number,
    notReviewedCount: number
  ): JSX.Element => {
    const title = LocaleKeys.shouldContinueAccess;
    // label Type maybe user. group, principals, or approval
    // TODO: change to real approval type
    const labelType = LocaleKeys.accessReviewApproval;
    let approvalSelectedMessage = t(LocaleKeys.approvalSelected, { count: totalCount });

    // If filtered by notReviewed only display notReviewed approvals
    if (this.state.decisionFilter.selectedDecisions?.length === 1
      && this.state.decisionFilter.selectedDecisions[0] === DecisionType.NotReviewed) {
      approvalSelectedMessage = notReviewedCount <= 1 ? t(LocaleKeys.approvalSelected, { count: notReviewedCount })
        : t(LocaleKeys.approvalSelected, { count: notReviewedCount, context: 'plural' });
    }

    let decisionMessage = t(LocaleKeys.approveAccess);
    let reasonRequired = true;
    let disableSubmit = false;
    let iconName = 'StatusCircleCheckMark';

    if (this.state.decisionType === DecisionType.Deny) {
      reasonRequired = false;
      decisionMessage = t(LocaleKeys.denyAccess);
      iconName = 'StatusCircleErrorX';
    }

    if (this.state.decisionType === DecisionType.DontKnow) {
      reasonRequired = false;
      decisionMessage = t(LocaleKeys.dontKnow);
      iconName = 'StatusCircleQuestionMark';
    }

    if (reasonRequired && this.state.justification === '') {
      disableSubmit = true;
    }

    return (
      <Dialog
        hidden={false}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        title={t(title, { decisionSubjectType: this.props.decisionSubjectType, resourceName: this.props.resourceName })}
        modalProps={{
          containerClassName:
            responsiveMode! < ResponsiveMode.large
              ? ''
              : myAccessStyles.wideDialog
        }}
      >
        {!isSubmitting ? (
          <div>
            <div className={css(myAccessStyles.marginBottomXSmall, myAccessStyles.bold)}>
              {t(approvalSelectedMessage)}
            </div>
            <div
              className={css(
                myAccessStyles.marginTopSmall,
                myAccessStyles.marginBottomMedium,
                myAccessStyles.paddingSmallPlus,
                ColorClassNames.neutralLighterBackground,
                myAccessStyles.bold)}
            >
              <Icon iconName={iconName} />
              {t(LocaleKeys.decisionType, { type: decisionMessage })}
              <TextField
                label={t(LocaleKeys.reason)}
                value={this.state.justification}
                className={'ms-pii'}
                required={reasonRequired}
                onChange={this._onJustificationChanged}
                disabled={isSubmitting}
                multiline
              />
            </div>
          </div>
        ) : null}
        <DialogFooter>
          {isSubmitting ? (
            getSpinner(asLocalizedText(submittingText || LocaleKeys.loading, t))
          ) : (
            <div>
              <DefaultButton
                onClick={() => this._submitAllDecisionWithFilter()}
                text={t(LocaleKeys.submit)}
                className={css(myAccessStyles.marginRightSmall)}
                disabled={disableSubmit}
              />
              <DefaultButton
                onClick={() => this._switchToReviewDialog(this.state.decisionFilter)}
                text={t(LocaleKeys.back)}
                className={css(myAccessStyles.marginRightSmall)}
              />
            </div>
          )}
        </DialogFooter>
      </Dialog>
    );
  }

  private readonly _getRecommendationDialog = (
    isSubmitting: boolean,
    submittingText?: string | ILocalizableMessage,
    t: TranslationFunction,
    responsiveMode?: ResponsiveMode,
    totalCount: number,
    notReviewedCount: number
  ): JSX.Element => {

    const title = LocaleKeys.acceptOurRecommendations;
    // label Type maybe user. group, principals, or approval
    // TODO: change to real approval type
    const labelType = LocaleKeys.accessReviewApproval;
    let approvalSelectedMessage = LocaleKeys.approvalSelected;
    if (this.state.decisionFilter.selectedDecisions?.length === 1
      && this.state.decisionFilter.selectedDecisions[0] === DecisionType.NotReviewed) {
      approvalSelectedMessage = notReviewedCount <= 1 ? t(LocaleKeys.approvalSelected, { count: notReviewedCount })
        : t(LocaleKeys.approvalSelected, { count: notReviewedCount, context: 'plural' });
    } else {
      approvalSelectedMessage = totalCount <= 1 ? t(LocaleKeys.approvalSelected, { count: totalCount })
        : t(LocaleKeys.approvalSelected, { count: totalCount, context: 'plural' });
    }

    const signInActivityScope = this.props.resourceType === AccessReviewType.App
      ? LocaleKeys.application : LocaleKeys.tenant;
    return (
      <Dialog
        hidden={false}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        title={t(title)}
        modalProps={{
          containerClassName:
            responsiveMode! < ResponsiveMode.large
              ? ''
              : myAccessStyles.wideDialog
        }}
      >
        {!isSubmitting ? (
          <div>
            <div className={css(myAccessStyles.marginTopXSmall, myAccessStyles.marginBottomSmall)}>
              {t(LocaleKeys.recommendationBaseOn)}
            </div>
            <div className={css()}>
              <Icon
                iconName={'FollowUser'}
                className={css(
                  myAccessStyles.smallIconAlign,
                  myAccessStyles.marginRightSmall,
                  myAccessStyles.marginBottomMedium
                )}
              />
              {t(LocaleKeys.signInActivityAtTenant, { signInActivityScope })}
            </div>
            {/* Enable after peer access feature available
            <div className={css(myAccessStyles.marginBottomMedium)}>
              <Icon
                iconName={'Group'}
                className={css(
                  myAccessStyles.smallIconAlign,
                  myAccessStyles.marginRightSmall
                )}
              />
              {t(LocaleKeys.peerAccess)}
            </div>
                */}
            <div className={css(myAccessStyles.marginBottomXSmall, myAccessStyles.bold)}>
              {t(approvalSelectedMessage)}
            </div>
            <div className={css(myAccessStyles.marginTopSmall, myAccessStyles.marginBottomSmall)}>
              {t(LocaleKeys.acceptRecommendationsForApprovalSelected)}
            </div>
          </div>
        ) : null}
        <DialogFooter>
          {isSubmitting ? (
            getSpinner(asLocalizedText(submittingText || LocaleKeys.loading, t))
          ) : (
            <div>
              <PrimaryButton
                // Override apply review result to all approval
                onClick={() => this._submitAllDecisionWithFilter()}
                text={t(LocaleKeys.accept)}
                className={css(myAccessStyles.marginRightSmall)}
              />
              <DefaultButton
                onClick={this.props.onDismiss}
                text={t(LocaleKeys.discard)}
                className={css(myAccessStyles.marginRightSmall)}
              />
            </div>
          )}
        </DialogFooter>
      </Dialog>
    );
  }

  private readonly _switchToReviewDialog = (filter: IFilter): void => {
    this.setState({
      dialogType: DialogType.Review,
      decisionFilter: filter
    });
  }

  private readonly _switchToOverrideDialog = (filter: IFilter): void => {
    this.setState({
      dialogType: DialogType.Override,
      decisionFilter: filter
    });
  }

  private readonly _switchToRecommendationDialog = (filter: IFilter): void => {
    this.setState({
      dialogType: DialogType.Recommendation,
      decisionType: DecisionType.AcceptRecommendation,
      decisionFilter: filter
    });
  }

  private readonly _switchToJustificationDialog = (decisionType: string): void => {
    this.setState({
      dialogType: DialogType.Justification,
      decisionType
    });
  }

  private readonly _onJustificationChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      ...this.state,
      justification: newValue!
    });
  }

  private _overriddenDecisionCount(totalCount: number, notReviewedCount: number): number {
    return totalCount - notReviewedCount;
  }

  private _getFilter(selectedDecisionsFilter: string[], selectedRecommendationsFilter: string[]): IFilter {
    const filter = {
      selectedDecisions: selectedDecisionsFilter,
      selectedRecommendations: selectedRecommendationsFilter
    };

    this.setState({
      decisionFilter: filter
    });

    return filter;
  }

  private readonly _submitAllDecisionWithFilter = (): void => {

    this.props.submitAllDecisions({
      reviewId: this.props.reviewId,
      decisionType: this.state.decisionType,
      justification: this.state.justification,
      filterContext: this.state.decisionFilter
    } as ISubmitDecision);
  }

}
