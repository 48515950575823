import { TranslationFunction } from 'i18next';
import {
  CheckboxVisibility,
  css,
  DefaultButton,
  DetailsList,
  DetailsRow,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IGroupHeaderStyles,
  IRenderFunction,
  ScrollablePane,
  IDetailsCheckboxProps,
  Selection,
  IGroupHeaderCheckboxProps,
  Checkbox,
  SelectionMode,
  IGroup,
  Spinner,
  GroupHeader,
  SpinnerSize,
  Sticky,
  FontClassNames,
  ProgressIndicator,
  Icon
} from '@fluentui/react';
import * as React from 'react';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { INoEntitiesProps, NoEntities } from '../NoEntities/NoEntities';
import { NoFilteredResults } from '../NoFilteredResults/NoFilteredResults';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { getInlineSpinner } from '../../../shared/spinner';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

export interface IGroupedInfinityListProps {
  entityList: IEntity[];
  groupEntityToChildren: Record<string, ReadonlyArray<IEntity>>;
  entityType?: EntityType;
  ariaLabel: string;
  columns: IListColumn<IEntity>[];
  showLoadMore: boolean;
  showSpinner: boolean;
  spinnerLabel: string;
  showNoEntities: boolean;
  noEntitiesProps?: INoEntitiesProps;
  showNoFilteredResults: boolean;
  selectionMode: SelectionMode;
  searchTerm?: string | null;
  selectedFilterKey?: string | null;
  clearSelection?: boolean;
  selectAll?: boolean;
  onClearSelection?: () => void;
  checkboxVisibility?: CheckboxVisibility;
  showColumnHeaders?: boolean;
  t: TranslationFunction;
  onLoadMore(): void;
  onItemSelected?(selectedItems: IEntity[]): void;
  onRowClicked?(item: IEntity): void;
  onSetHoveredKey?(key: string | null): void;
  onRender?(item: IEntity, detailsRowProps: IDetailsRowProps): JSX.Element;
  onToggleCollapse?(group: IGroup): void;
  onToggleAllCollapse?(allCollapsed: boolean): void;
  groups: IGroup[];
  isSubmitting: boolean;
  onToggleSelectAll?(selectAll: boolean): void;
}

export class GroupedInfinityList extends React.Component<IGroupedInfinityListProps> {
  private _selection: Selection;
  private _entityCollapsedState: { [key: string]: boolean };

  constructor(nextProps: IGroupedInfinityListProps) {
    super(nextProps);
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.props.onItemSelected && this.props.onItemSelected(this.getSelectedItems());
        this._selection.isAllSelected() ? this.props.onToggleSelectAll!(true) : this.props.onToggleSelectAll!(false);
      }
    });
    this._entityCollapsedState = {};
  }

  public componentDidUpdate(prevProps: IGroupedInfinityListProps): void {
    if (this.props.clearSelection && !prevProps.clearSelection) {
      this._selection.setAllSelected(false);
      if (this.props.onClearSelection) {
        this.props.onClearSelection();
      }
    }
    if (this.props.selectAll && !prevProps.selectAll) {
      this._selection.setAllSelected(true);
    }
  }

  public shouldComponentUpdate(prevProps: IGroupedInfinityListProps) {
    if (
      this.props.entityList.length !== prevProps.entityList.length
      || this.props.isSubmitting
      || this.props.clearSelection
      || this.props.showSpinner
      || (this.props.searchTerm !== prevProps.searchTerm)) {
      if (this.props.clearSelection && !prevProps.clearSelection) {
        this._selection.setAllSelected(false);
      }
      return true;
    }
    return false;
  }

  public render(): JSX.Element {
    const {
      entityList,
      ariaLabel,
      showLoadMore,
      showSpinner,
      spinnerLabel,
      columns,
      onLoadMore,
      showNoEntities,
      showNoFilteredResults,
      noEntitiesProps,
      selectionMode,
      showColumnHeaders,
      t,
      checkboxVisibility,
      groups
    } = this.props;


    const onRenderGroupHeaderCheckbox = (props?: IGroupHeaderCheckboxProps) => {
      const iconStyles = { root: { FontFace: FontClassNames.smallPlus } };
      return (
        <>
          <Checkbox checked={props?.checked} styles={iconStyles} />
        </>
      )
    };

    const checkboxStyles: Partial<IGroupHeaderStyles> = {
      check: {
        opacity: 1,
      }
    };

    const list = (
      <DetailsList
        className={css(myAccessStyles.marginBottomSmall, myAccessListStyles.list)}
        ariaLabel={ariaLabel}
        isHeaderVisible={showColumnHeaders!}
        checkboxVisibility={checkboxVisibility || CheckboxVisibility.always}
        columns={columns}
        groups={groups}
        items={entityList}
        setKey="id"
        // This is a workaround to be able to render checkbox instead of the default radio button as described here: https://github.com/microsoft/fluentui/issues/10073
        onRenderCheckbox={(props?: IDetailsCheckboxProps) => {
          return (
            <>
              <Checkbox {...props} checked={props?.checked} inputProps={{ 'data-selection-disabled': true, onClick: (ev: any) => ev.stopPropagation() } as any} />
            </>
          );
        }}
        onRenderDetailsHeader={
          // tslint:disable-next-line:jsx-no-lambda
          (detailsHeaderProps?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => (
            <Sticky stickyClassName={css(myAccessListStyles.list)}>
              <div className={css(myAccessListStyles.listColumnHeader)}>{defaultRender!(detailsHeaderProps)}</div>
            </Sticky>
          )
        }
        onRenderRow={this.onRenderRow as IRenderFunction<IDetailsRowProps>}
        selectionMode={selectionMode}
        selection={this._selection}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForListHeader={'Column headers. User menus to perform column operations like sort and filter'}
        ariaLabelForSelectionColumn={'Toggle selection'}
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="select row"
        checkboxCellClassName='ms-DetailsRow-checkCell'
        groupProps={{
          isAllGroupsCollapsed: true,
          showEmptyGroups: true,
          onToggleCollapseAll: this.onToggleCollapseAll,
          onRenderHeader: (props) => {
            const groupHeaderProps = {
              ...props,
              columns: columns,
              className: myAccessListStyles.supervisorCentricGroupedHeader,
              styles: checkboxStyles,
            };

            // Set the isCollapsed state to what it was
            let key = props?.group?.key;
            if (props && props.group && key) {
              var isCollapsed = this._entityCollapsedState[key];
              if (isCollapsed === undefined) {
                props.group.isCollapsed = true;
                this._entityCollapsedState[key] = true;
              } else {
                props.group.isCollapsed = isCollapsed;
              }
            }

            let customContent: JSX.Element | string = '';
            switch (this.props.entityType) {
              case EntityType.accessReviewDecisions:
                let item = entityList.find((entity) => entity.id === props?.group?.key) as IAccessReviewDecision;
                let decisionItem = this.props.groupEntityToChildren[props?.group?.key!];
                let decisionCounts = undefined;
                if (decisionItem && decisionItem.length > 0) {
                  item = decisionItem[0] as IAccessReviewDecision;

                  // Loop through the decisions to count the number of decisions
                  decisionCounts = decisionItem.reduce((counts, decision) => {
                    let arDecisions = decision as IAccessReviewDecision;
                    if (arDecisions && arDecisions.reviewResult !== undefined && arDecisions.reviewResult !== 'NotReviewed') {
                      counts.totalMadeDecisions++;
                    }
                    counts.totalDecisions++;
                    return counts;
                  }, { totalDecisions: 0, totalMadeDecisions: 0 });
                }
                customContent = (
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <div className={css('ms-pii', FontClassNames.mediumPlus, myAccessStyles.primaryText, myAccessStyles.bold)}>
                          {item?.principal?.displayName}
                        </div>
                        <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.secondaryText)}>
                          {item?.principal?.userPrincipalName}
                        </div>
                      </div>
                      <div style={{ minWidth: 80 }}>
                      </div>
                      <div style={{ minWidth: 200 }}>
                        {
                          (decisionCounts) ?
                            (
                              <div style={{ position: 'relative' }}>
                                <ProgressIndicator
                                  label={this._getReviewProgressString(decisionCounts.totalMadeDecisions, decisionCounts.totalDecisions)}
                                  percentComplete={this._getReviewProgressPercentage(decisionCounts.totalMadeDecisions, decisionCounts.totalDecisions)}
                                  barHeight={4}
                                  ariaValueText={t(LocaleKeys.accessReviewsProgress)}
                                  styles={{
                                    progressBar: {
                                      backgroundColor: (decisionCounts.totalMadeDecisions / decisionCounts.totalDecisions) === 1 ? 'green' : undefined
                                    }
                                  }}
                                />
                                {this._getReviewProgressPercentage(decisionCounts.totalMadeDecisions, decisionCounts.totalDecisions) === 1 && (
                                  <Icon iconName="SkypeCircleCheck" className={css(myAccessStyles.checkmarkIcon)} style={{ position: 'absolute', right: '-10px', top: '60%', color: 'green' }} />
                                )}
                              </div>
                            ) : null}
                      </div>
                    </div>
                  </div>
                )
                break;
            }

            return (
              <GroupHeader
                {...groupHeaderProps}
                onToggleCollapse={this.onToggleOnCollapse}
                onRenderTitle={(props) => {
                  return (
                    <div>
                      {customContent ? customContent : props?.group?.name}
                    </div>
                  )
                }}
                onRenderGroupHeaderCheckbox={onRenderGroupHeaderCheckbox}
              />
            );
          }
        }}
      />
    );

    const richList = (
      <div
        // tslint:disable-next-line:jsx-ban-props
        style={{ overflowX: 'hidden' }}
        // tslint:disable-next-line:jsx-no-lambda
        onMouseLeave={() => (this.props.onSetHoveredKey ? this.props.onSetHoveredKey(null) : null)}
      >
        {list}
        {showLoadMore ? (
          <div className={css(myAccessStyles.alignCenter)}>
            <DefaultButton onClick={onLoadMore} text={t(LocaleKeys.loadMore)} />
          </div>
        ) : null}
        {showSpinner ? <Spinner size={SpinnerSize.medium} label={spinnerLabel} /> : null}
        {showNoEntities && noEntitiesProps ? (
          <NoEntities
            iconName={noEntitiesProps.iconName}
            noRowMessage={t(noEntitiesProps.noRowMessage)}
            showButton={noEntitiesProps.showButton}
            buttonText={t(noEntitiesProps.buttonText!, {
              context: 'capitalize'
            })}
            onButtonClick={noEntitiesProps.onButtonClick}
          />
        ) : null}
        {showNoFilteredResults ? <NoFilteredResults t={t} /> : null}
      </div>
    );

    return (
      <div
        id="entityList"
        role="region"
        aria-labelledby="entityList"
        // tslint:disable-next-line:jsx-ban-props
        style={{
          position: 'relative',
          height: '100%',
          maxHeight: 'inherit',
          minHeight: '416px'
        }}
      >
        <ScrollablePane
          styles={{
            stickyBelow: myAccessListStyles.stickybelowitems
          }}
        >
          {richList}
        </ScrollablePane>
      </div>
    );
  }

  private onRenderRow = (props: IDetailsRowProps): JSX.Element => {
    // For access review decisions we want to
    // show a spinner first while we fetch the resource details
    if (this.props.entityType === EntityType.accessReviewDecisions) {
      if (props.item && props.item.resource === undefined) {
        return getInlineSpinner();
      }
    }

    if (this.props.onRender) {
      return this.props.onRender(props.item, props);
    }

    const detailsRowProps = {
      ...props,
      className: myAccessListStyles.listRow,
      onClick: () => this.props.onRowClicked && this.props.onRowClicked(props.item)
    };
    return <DetailsRow {...detailsRowProps} />;
  };

  private getSelectedItems = (): IEntity[] => {
    const selectedItems = this._selection.getSelection() as IEntity[];
    return selectedItems;
  };

  private onToggleOnCollapse = (group: IGroup): void => {
    group.isCollapsed = !group.isCollapsed;
    this._entityCollapsedState[group.key] = group.isCollapsed;
    if (this.props.onToggleCollapse) {
      this.props.onToggleCollapse(group);
    }
    this.forceUpdate();
  }

  private onToggleCollapseAll = (isCollapsed: boolean): void => {
    const updatedEntityCollapsedState = { ...this._entityCollapsedState };
    Object.keys(updatedEntityCollapsedState).forEach((key) => {
      updatedEntityCollapsedState[key] = isCollapsed;
    });
    this._entityCollapsedState = updatedEntityCollapsedState;
    if (this.props.onToggleAllCollapse) {
      this.props.onToggleAllCollapse(isCollapsed);
    }
    this.forceUpdate();
  };

  private _getReviewProgressString(totalDecisionsMade: number, totalDecisions: number): string {
    if (totalDecisionsMade > 0 && totalDecisions > 0) {
      if (totalDecisions === totalDecisionsMade) {
        return this.props.t(LocaleKeys.completed);
      } else {
        return this.props.t(LocaleKeys.inProgressText) + ' (' + totalDecisionsMade.toString() + ' ' + this.props.t(LocaleKeys.of).toString() + ' ' + totalDecisions.toString() + ' ' + this.props.t(LocaleKeys.pending).toString() + ')';
      }
    } else {
      return '';
    }
  }

  private _getReviewProgressPercentage(totalDecisionsMade: number, totalDecisions: number): number {
    if (totalDecisionsMade > 0 && totalDecisions > 0) {
      return totalDecisionsMade / totalDecisions;
    } else {
      return 0;
    }
  }
}
