import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions, IAvailableAccessPackageDetails } from '../models';
import { useSelector } from '.';
import { GetEligibleAccessPackageActionPayload } from '../redux';
import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';

interface IUseGetEligibleAccessPackage {
  getEligibleAccessPackage: (payload: GetEligibleAccessPackageActionPayload) => void;
  clearValidationErrors: () => void;
  isLoading: boolean;
  loadingState: LoadingState;
  availableAccessPackageDetail: IAvailableAccessPackageDetails;
}

export const useGetEligibleAccessPackage = (): IUseGetEligibleAccessPackage => {
  const dispatch = useDispatch();
  const {eligibleAccessPackage} = useSelector((state) => ({
    eligibleAccessPackage: state?.app?.availableAccessPackageDetails
  }));

  return {
    getEligibleAccessPackage: createAction<GetEligibleAccessPackageActionPayload>(EntitlementActions.getEligibleAccessPackageDetail, dispatch),
    clearValidationErrors: createAction(EntitlementActions.clearValidationErrors, dispatch),
    isLoading: eligibleAccessPackage.isLoading,
    loadingState: eligibleAccessPackage.loadingState?? LoadingState.notLoaded,
    availableAccessPackageDetail: eligibleAccessPackage.value?? {} as IAvailableAccessPackageDetails
  };
};