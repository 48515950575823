import { useSetTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback } from 'react';

import { EU_DATA_BOUNDARY_CLAIM_VALUE } from '../shared/constants';
import { createTelemetryObject, telemetry, telemetryEnvironmentConfiguration } from '../shared/telemetry';

export interface TelemetryUtils {
  readonly updateTelemetryReference: (isRegionalEndpointEnabled: boolean, dataBoundary?: string) => void;
}

export function useTelemetryUtils(): TelemetryUtils {
  const setTelemetry = useSetTelemetry();
  const updateTelemetryReference = useCallback(
    (isRegionalEndpointEnabled: boolean, dataBoundary?: string) => {
      const prevTelemetryObject = telemetry;
      if (dataBoundary?.toLocaleLowerCase() === EU_DATA_BOUNDARY_CLAIM_VALUE) {
        if (isRegionalEndpointEnabled) {
          setTelemetry(createTelemetryObject(telemetryEnvironmentConfiguration.EU));
          prevTelemetryObject.destroy();
        }
      } else {
        setTelemetry(createTelemetryObject(telemetryEnvironmentConfiguration.WW));
        prevTelemetryObject.destroy();
      }
    },
    [setTelemetry]
  );

  return {
    updateTelemetryReference
  };
}
