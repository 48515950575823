import { User } from '@microsoft/microsoft-graph-types';
import { ILoadable } from '@microsoft/portal-app/lib/models/ILoading';
import { InjectedTranslateProps } from 'react-i18next';

import { IGrantRequestTargetType } from '../../../models';
import { IGrantPolicy } from '../../../models/ELM/IGrantPolicy';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IRoleAssignment } from '../../../models/ELM/IRoleAssignment';
import { IRoleScope } from '../../../models/ELM/IRoleScope';
import { ISubject } from '../../../models/ELM/ISubject';
import { IValidationError } from '../../../models/ELM/IValidationError';

export interface IRequestGrantPanelProps
  extends IRequestGrantPanelActions,
    IRequestGrantPanelMappedProps,
    InjectedTranslateProps {
  enableVerifiableCredential: boolean;
  entitlementResources?: IRoleScope[] | IRoleAssignment[] | null;
  canViewResources?: boolean;
  entitlementUrl: string;

  /* This prop indicates if resource role scope is loaded from access package list expand or not.
     Value is false for suggestion list, and true for available access package list.
  */
  isResourceLoaded?: boolean;
  onDismiss: () => void;
  onSubmitRequest: () => void;
}

export interface IRequestGrantPanelMappedProps extends IConnectedRequestGrantPanelProps {
  oboAllowed: boolean;
  oboChecked: boolean;
  enableVerifiableCredential: boolean;
  grantRequest?: Partial<IGrantRequest>;
  policy?: IGrantPolicy;
  policiesContainVerifiableCredentials: boolean;
  noPolicyOptions: boolean;
  submitting: boolean;
  validationErrors: IValidationError[];
  needsConsent: boolean;
  policiesLoading: boolean;
  allPoliciesRequireVerifiableCredentials: boolean;
  invalidRequestExistingGrant: boolean;
  notRequestableForMyself: boolean;
  existingOpenRequest: boolean;
  policiesDefined: boolean;
  grantTarget?: ISubject;
  grantTargetType?: IGrantRequestTargetType;
  loadableDirectReports: ILoadable<readonly User[]>;
  entitlementId?: string;
  navigateTo: (url: string) => void;
}

export interface IRequestGrantPanelActions {
  showCopyLinkNotification: () => void;
  recordGrantRequestDetailedEvent: (secondsElapsed: number, usedVerifiableCredentials: boolean) => void;
  postGrantRequest: (payload: { newGrantRequest: Partial<IGrantRequest>; entitlementName: string }) => void;
  clearValidationErrors: () => void;
  getRequestPolicies: (request: Partial<IGrantRequest>) => void;
  getHasOBOPolicies: (request: Partial<IGrantRequest>) => void;
}

export enum RequestGrantState {
  SelectPolicy,
  VerifiableCredential,
  Questions,
  ConsentForm,
  ResourceView
}

export interface IRequestGrantPanelState {
  requestState: RequestGrantState;
  verifiableCredentialIntervalHandle?: NodeJS.Timer;
  startTimeUnixEpoch: number;
  consented: boolean;
}

export interface IConnectedRequestGrantPanelProps {
  title?: string;
  grantRequest?: Partial<IGrantRequest>;
  entitlementName?: string;
  entitlementDescription?: string;
}
