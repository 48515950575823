import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';

import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod, AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import { catchError, map } from 'rxjs/operators';

import { IAvailableAccessPackageDetails } from '../../../models';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { getAudience } from '../../../shared/AttachAudience';
import { getRequestableAccessPackageDetailApiUrl } from '../../../shared/getApiUrl';
import { registry } from '../myAccessRegistry';

export interface GetEligibleAccessPackageActionPayload {
  entityId: string;
  isPollingForStatus?: boolean;
}

/**
 * internal interface used to transfer accessPackageId with policy results to success action
 */
interface IEligibleAccessPackageResult {
  accessPackageId: string;
  availableAccessPackageDetails?: IAvailableAccessPackageDetails;
}

export type IGetEligibleAccessPackageAction = IEntitlementAction<GetEligibleAccessPackageActionPayload>;
export type IGetEligibleAccessPackageSuccessAction = IEntitlementAction<IEligibleAccessPackageResult>;
export type IGetEligibleAccessPackageFailAction = IEntitlementAction<Readonly<AnyPayload>, MetaEligibleAccessPackageFailed>;

// a new epic is required because this is a POST request
export const getEligibleAccessPackageEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IGetEligibleAccessPackageAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$.ofType(EntitlementActions.getEligibleAccessPackageDetail).mergeMap((action: IGetEligibleAccessPackageAction) => {
    if (!action?.payload?.entityId) {
      throw new Error('getPoliciesEpic: entityId and parameters are required');
    }

    const { entityId } = action.payload;
    const ajaxRequest: IAjaxRequest = {
      url: getRequestableAccessPackageDetailApiUrl(entityId),
      audience: getAudience(EntityType.entitlements),
      method: 'GET',
    };
    return ajax(ajaxRequest)
    .timeout(10000)
    .pipe(
      map((payload: AjaxResponse): IGetEligibleAccessPackageSuccessAction => {
        return {
          type: EntitlementActions.getEligibleAccessPackageDetailSucceeded,
          payload: {
            accessPackageId: entityId,
            availableAccessPackageDetails: payload.response!,
          }
        };
      }),
      catchError(errorHandler<IEntitlementAction>(EntitlementActions.getEligibleAccessPackageDetailFailed, action))
    );
  });
};
registry.addEpic('getEligibleAccessPackageDetail', getEligibleAccessPackageEpic);

export const getEligibleAccessPackageDetail = (state: IEntitlementState, action: IGetEligibleAccessPackageAction): Readonly<IEntitlementState> => {
  if (action.payload === undefined || !action.payload.entityId) {
    return state;
  }

  const newState = {
    ...state,
    availableAccessPackageDetails: {
      isLoading: true,
      LoadingState: LoadingState.loading,
      value: null
    }
  };

  return newState;
};
registry.add(EntitlementActions.getEligibleAccessPackageDetail, getEligibleAccessPackageDetail);

export const getEligibleAccessPackageSucceeded = (
  state: IEntitlementState,
  action: IGetEligibleAccessPackageSuccessAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || action.payload === null) {
    return state;
  }

  return {
    ...state,
    availableAccessPackageDetails: {
      isLoading: false,
      loadingState: LoadingState.loaded,
      value: action.payload.availableAccessPackageDetails?? {} as IAvailableAccessPackageDetails,
    },
    errorHasOccurred: false
  };
};
registry.add(EntitlementActions.getEligibleAccessPackageDetailSucceeded, getEligibleAccessPackageSucceeded);

export type MetaEligibleAccessPackageFailed = { parent?: IGetEligibleAccessPackageAction } | undefined;
export const getEligibleAccessPackageFailed = (
  state: IEntitlementState,
  action: IGetEligibleAccessPackageFailAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || action.meta?.parent?.payload?.entityId === undefined) {
    return state;
  }

  return {
    ...state,
    availableAccessPackageDetails:{
      isLoading: false,
      loadingState: LoadingState.error,
      value: {} as IAvailableAccessPackageDetails,
    }
  };
};
registry.add(EntitlementActions.getEligibleAccessPackageDetailFailed, getEligibleAccessPackageFailed);
