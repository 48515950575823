import { useSelector, useTranslation } from '../index';
import { EntitlementActions } from '../../models';
import { useDispatch } from 'react-redux';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { IOverviewData, OverviewErrorEventName, OverviewType } from '../../models/IOverviewData';
import { checkFeatureAccess, LocaleKeys, Routes } from '../../shared';
import { useHistory } from 'react-router';
import { isNullOrUndefined } from 'util';
import { IOverviewActionCardProps } from '../../components/Shared/OverviewCard';

export const useOverviewAccessReviewManager = () => {
  const {
    pendingGroupReviewData,
    pendingAccessPackageReviewData,
    pendingUserAccessReviewData,
    supervisorCentricAccessReviews } = useSelector((state) => ({
    pendingGroupReviewData: state.app.overviewGroupReviewData,
    pendingAccessPackageReviewData: state.app.overviewAccessPackageReviewData,
    pendingUserAccessReviewData: state.app.overviewUserAccessReviewData,
    supervisorCentricAccessReviews: state.app.features.isEnabled.supervisorCentricAccessReviews
  }));
  
  const dispatch = useDispatch();
  const t = useTranslation();
  const getGroupReviewCount = createAction(EntitlementActions.getGroupReviewCount, dispatch);
  const getAccessPackageReviewCount = createAction(EntitlementActions.getAccessPackageReviewCount, dispatch);
  const getUserAccessReviewCount = createAction(EntitlementActions.getUserAccessReviewCount, dispatch);

  const history = useHistory();

  const viewAccessReviews = () => {
    let path = Routes.accessReviews;
    history.push(path);
  };

  const buildOverviewAccessReviewCardData =
  (groupReviewOverviewData: IOverviewData, accessPackageOverviewData: IOverviewData, userAccessOverviewData: IOverviewData): IOverviewActionCardProps => {
    let overviewAccessReviewCardData: IOverviewActionCardProps = {
      id: OverviewType.pendingAccessReviews,
      title: t(LocaleKeys.accessReview),
      cardDescription: t(LocaleKeys.pendingAccessReview),
      cardImageUrl: '/imgs/overview_AccessReview.png',
      primaryButtonLabel: t(LocaleKeys.reviewNow),
      isLoading: true,
      isSucceeded: false,
      primaryButtonAction: viewAccessReviews,
      refreshAction: ()=> {
        getGroupReviewCount();
        getAccessPackageReviewCount();
        supervisorCentricAccessReviews ? getUserAccessReviewCount() : null;
      }
    };

    if (!isNullOrUndefined(groupReviewOverviewData) && !isNullOrUndefined(accessPackageOverviewData) && !isNullOrUndefined(userAccessOverviewData)) {
      overviewAccessReviewCardData.isLoading = groupReviewOverviewData.isLoading || accessPackageOverviewData.isLoading || (supervisorCentricAccessReviews ? userAccessOverviewData.isLoading : false);
      overviewAccessReviewCardData.isSucceeded = groupReviewOverviewData.isSucceeded && accessPackageOverviewData.isSucceeded && (supervisorCentricAccessReviews ? userAccessOverviewData.isSucceeded : true);
      overviewAccessReviewCardData.pendingActionEntityCount =
        groupReviewOverviewData.pendingActionEntityCount! + accessPackageOverviewData.pendingActionEntityCount! + (supervisorCentricAccessReviews ? userAccessOverviewData.pendingActionEntityCount! : 0);
      overviewAccessReviewCardData.errorEventName =
        groupReviewOverviewData.errorEventName && accessPackageOverviewData.errorEventName && (supervisorCentricAccessReviews ? userAccessOverviewData.errorEventName: true)
        ? OverviewErrorEventName.accessReviewError
        : groupReviewOverviewData.errorEventName || accessPackageOverviewData.errorEventName || userAccessOverviewData.errorEventName;
      overviewAccessReviewCardData.error = [...(groupReviewOverviewData.error || []), ...(accessPackageOverviewData.error || [])];
      if (overviewAccessReviewCardData.pendingActionEntityCount && overviewAccessReviewCardData.pendingActionEntityCount > 1) {
        overviewAccessReviewCardData.cardDescription = t(LocaleKeys.pendingAccessReview, {context: 'plural'});
      }
    }

    return overviewAccessReviewCardData;
  };

  const accessReviewData: IOverviewActionCardProps =
   buildOverviewAccessReviewCardData(pendingGroupReviewData, pendingAccessPackageReviewData, pendingUserAccessReviewData);

  if (supervisorCentricAccessReviews) {
    return {accessReviewData, getGroupReviewCount, getAccessPackageReviewCount, getUserAccessReviewCount, viewAccessReviews};
  } else {
    return {accessReviewData, getGroupReviewCount, getAccessPackageReviewCount, viewAccessReviews};
  }
};