import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback } from 'react';

import { IEntitlementState } from '../models';
import { generateDescription, getFeatures, getPath } from '../redux/modules/entitlement';
import { useRedux } from './';

interface IUseTelemetryWithMetadata {
  reportCustomEventWithMetadata: (eventName: string, payload?: {}) => void;
  reportErrorWithMetadata: (error: string, payload?: {}) => void;
}

export function useTelemetryWithMetadata(): IUseTelemetryWithMetadata {
  const telemetry = useTelemetry();
  const [state] = useRedux();
  const path: string = getPath(state);
  const appState: IEntitlementState = state?.app;
  const features = getFeatures(appState);

  const reportCustomEventWithMetadata = useCallback(
    (eventName: string, payload?: {}): void => {
      const description = generateDescription(path, features, payload);
      telemetry.reportCustomEvent(eventName, { message: description });
    },
    [telemetry, path, features]
  );

  const reportErrorWithMetadata = useCallback(
    (error: string, payload?: {}): void => {
      const description = generateDescription(path, features, payload);
      telemetry.error(error, { message: description });
    },
    [telemetry, path, features]
  );

  return { reportCustomEventWithMetadata, reportErrorWithMetadata };
}
