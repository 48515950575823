import { IShimmerColors, Link, Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';
import { IAuthTokenHints, IAuthUser } from '@iamexperiences/react-auth/lib-commonjs/core';
import { useAuth } from '@iamexperiences/react-auth/lib-commonjs/react';
import { IConfiguration } from '@iamexperiences/suite-header';
import { SuiteHeader } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader';
import { ILocalizationMap, IOrganization, IUserData } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader/SuiteHeader.types';
import { ITenantProps } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader/TenantList/ITenantProps';
import { AppKeys, getIAMUXAppsUrl, getIAMUXGroupsUrl } from '@microsoft/portal-app/lib/environment';
import { NavBarData } from '@suiteux/suiteux-shell-applauncher';
import i18next from 'i18next';
import { useCallback, useEffect } from 'react';
import * as React from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { useHeaderOrganizations } from '../../../hooks/header/userHeaderOrganizations';
import { useDataBoundary } from '../../../hooks/useDataBoundary';
import { useTelemetryUtils } from '../../../hooks/useTelemetryUtils';
import { TranslationFunctionKeys } from '../../../models';
import { IAppInfo } from '../../../models/Header/interfaces';
import { isInternalUser, LocaleKeys } from '../../../shared';
import { onLeavePreview } from '../../../shared/leavePreviewHelper';
import { settingsManager } from '../../../shared/settings';
import { ConnectedCustomSearchBox } from '../CustomSearch/ConnectedCustomSearchBox';
import { buildMyStarUrl, getMenuItems } from './enabledApps.util';
import { IHeaderProps } from './Header.types';

const DEFAULT_HELPLINK = 'https://docs.microsoft.com/en-us/azure/active-directory/user-help/my-account-portal-overview';
const MY_ACCESS_WORKLOAD_ID = 'MyAccess';
const SHIMMER_COLORS: IShimmerColors = { shimmer: '#eceaea', shimmerWave: '#dedcdc', background: '#f2f2f2' }

const getCultureFromClaims = (claims?: { [key: string]: unknown; }): string => {
  if (claims?.xms_pl && typeof claims.xms_pl === 'string') {
    return claims.xms_pl;
  }

  return i18next.languages[0];
}

const callGetNavBarDataWithAcquireToken = async (acquireToken: (resource?: string | readonly string[], tokenHints?: IAuthTokenHints) => Promise<string>, audience: string, getNavBarData: (token: string) => void): Promise<void> => {
  const token = await acquireToken(audience, {
    prompt: 'none',
    errorHandling: 'empty'
  });
  if (token !== '') {
    getNavBarData(token);
  }
}

const getAppsMap = (t: TranslationFunctionKeys, myAppsUrl?: string, myGroupsUrl?: string): Map<AppKeys, IAppInfo> => {
  const appsMap: Map<AppKeys, IAppInfo> = new Map<AppKeys, IAppInfo>();
  appsMap.set(
    AppKeys.access,
    {
      appKey: 'My Access',
      appName: t(LocaleKeys.myAccess),
      icon: 'AppIconDefaultList',
      defaultUrl: window.location.origin
    });

  myAppsUrl && appsMap.set(
    AppKeys.apps,
    {
      appKey: 'My Apps',
      appName: t(LocaleKeys.myApps),
      icon: 'AppIconDefaultList',
      iamuxUrl: getIAMUXAppsUrl(),
      defaultUrl: myAppsUrl
    });
  myGroupsUrl && appsMap.set(
    AppKeys.groups,
    {
      appKey: 'My Groups',
      appName: t(LocaleKeys.myGroups),
      icon: 'Group',
      iamuxUrl: getIAMUXGroupsUrl(),
      defaultUrl: myGroupsUrl
    });
  return appsMap;
};

const getUserDataFromAuth = (user: IAuthUser | null, currentOrganizationDisplayName?: string, userImageUrl?: string): IUserData => {
  const { tenantId, username, name, homeTenantId, rawClaims } = user || {};
  return {
    tenantId: tenantId || '',
    tenantDisplayName: currentOrganizationDisplayName || '',
    upn: username || '',
    name: name || '',
    imageUrl: userImageUrl,
    homeTenantId: homeTenantId || '',
    rawClaims: rawClaims || {}
  };
}

const getLocalizationMap = (t: TranslationFunctionKeys, currentOrganizationDisplayName?: string): ILocalizationMap => {
  return {
    apps: t(LocaleKeys.apps),
    switchOrgIntroText: t(LocaleKeys.switchOrgIntroText),
    currentOrganizationTitle: t(LocaleKeys.currentOrganizationTitle),
    otherOrganizationsTitle: t(LocaleKeys.otherOrganizationsTitle),
    manageOrganizations: t(LocaleKeys.manageOrganizationsLinkText),
    closePanelAriaLabel: t(LocaleKeys.closeButtonAriaLabel),
    tenantBrandingImageAlt: t(LocaleKeys.tenantBranding, { displayName: currentOrganizationDisplayName }),
    organizations: t(LocaleKeys.organizations),
    loadingOrganizations: t(LocaleKeys.loadingOrganizations),
    organizationsEmpty: t(LocaleKeys.emptyOrganizations),
    finishLoading: t(LocaleKeys.loaded),
    organizationError: t(LocaleKeys.organizationError),
    helpPanelHeaderText: t(LocaleKeys.help),
    viewApps: t(LocaleKeys.viewApps),
    helpPanelTermsOfUseLink: t(LocaleKeys.termsOfUse),
    helpPanelPrivacyLink: t(LocaleKeys.privacy),
    accountManager: t(LocaleKeys.accountManager),
    switchOrganization: t(LocaleKeys.switchOrganization),
    moreOptions: t(LocaleKeys.moreOptions),
    leaveNewExp: t(LocaleKeys.leaveNewExp),
    waffleNotAvailableHeader: t(LocaleKeys.fallbackWaffleHeaderText),
    waffleNotAvailableDescription: t(LocaleKeys.fallbackWaffleMainText),
    accessibilityLink: t(LocaleKeys.accessibilityLink)
  };
}

const getSuiteHeaderShimmerElements = (): JSX.Element => {
  return (
    <ShimmerElementsGroup
      shimmerElements={[
        { type: ShimmerElementType.line, width: '98%', height: 48 },
      ]}
    />
  );
};

export const Header: React.FC<IHeaderProps> = (props) => {
  const {
    navBarData,
    tenantBrandingUrl,
    currentOrganizationDisplayName,
    hasError,
    userImageUrl,
    getNavBarData,
    getUserImageUrl,
    handleAppBrandingClick,
    enableClaimBasedTelemetry
  } = props;

  const { user: userSubjectReadOnly, clientId, acquireToken, login, logout } = useAuth();
  const t = useTranslation();
  const { dataBoundary, isRegionalEndpointEnabled } = useDataBoundary();
  const { updateTelemetryReference } = useTelemetryUtils();
  const { reportCustomEventWithMetadata, reportErrorWithMetadata } = useTelemetryWithMetadata();

  const user = userSubjectReadOnly();
  const { tenantId, objectId, username } = user || {};
  const userData: IUserData = getUserDataFromAuth(user, currentOrganizationDisplayName, userImageUrl);

  const configuration: IConfiguration = { msGraphBaseUrl: settingsManager.getRequired('msGraphResourceName'), getToken: async () => await acquireToken() }
  const { organizations, isLoading: isLoadingOrganizations, error: headerOrganizationError } = useHeaderOrganizations(configuration, userData);

  const localizationMap: ILocalizationMap = getLocalizationMap(t, currentOrganizationDisplayName);
  const helpLink = settingsManager.get('panelHelpLink') || DEFAULT_HELPLINK;
  const evoBase = settingsManager.get('adalInstance') || 'https://login.microsoftonline.com';
  const viewAccountLink = settingsManager.get('meControlViewAccountLink') || '';
  const isUniversalMeControlEnabled = settingsManager.get('isUniversalMeControlEnabled') || false;
  const myGroupsUrl = settingsManager.get('myGroupsUrl');
  const myAppsUrl = settingsManager.get('myAppsUrl');

  const appsMap: Map<AppKeys, IAppInfo> = getAppsMap(t, myAppsUrl, myGroupsUrl);

  useEffect(() => {
    if (enableClaimBasedTelemetry) {
      updateTelemetryReference(isRegionalEndpointEnabled, dataBoundary);
    }
  }, [dataBoundary, isRegionalEndpointEnabled, updateTelemetryReference, enableClaimBasedTelemetry]);

  useEffect(() => {
    if (isInternalUser() && objectId) {
      getUserImageUrl(objectId);
    }
  }, [objectId, getUserImageUrl]);

  const audience = settingsManager.get('officeSuiteHeaderAudience');
  useEffect(() => {
    if (audience) {
      void callGetNavBarDataWithAcquireToken(acquireToken, audience, getNavBarData);
    } else {
      reportErrorWithMetadata('officeSuiteHeaderAudience setting not present', {});
    }
  }, [audience, acquireToken, getNavBarData, reportErrorWithMetadata]);

  const changeTenant = useCallback(
    (targetTenant: ITenantProps, currentTenant?: IOrganization) => {
      reportCustomEventWithMetadata('suite-header/switch-organization', {
        source: currentTenant?.displayName,
        target: targetTenant.displayName
      });
      history.replaceState('', '', '/#/');
      void login({ tenantId: targetTenant.tenantId });
    },
    [login, reportCustomEventWithMetadata]
  );

  const switchTo = useCallback(
    (upn: string) => {
      void login({
        username: upn,
        tenantId: 'common'
      });
    },
    [login]
  );

  const switchAccount = useCallback(() => {
    void login({
      tenantId: 'common',
      prompt: 'account'
    });
  }, [login]);

  const logOut = useCallback(() => {
    reportCustomEventWithMetadata('suite-header/MeControl/logOut', {
      message: 'The user logged out from MeControl'
    });

    void logout();
  }, [logout, reportCustomEventWithMetadata]);

  const handleHeaderRender = useCallback(() => {
    reportCustomEventWithMetadata('suite-header/rendered');
  }, [reportCustomEventWithMetadata]);

  const handleHeaderButtonClick = useCallback((buttonId: string) => {
    reportCustomEventWithMetadata('suite-header/button-clicked', { buttonId });
  }, [reportCustomEventWithMetadata]);

  const manageOrganizationControl = useCallback(() => {
    const organizationLink = buildMyStarUrl(settingsManager.get('myAccountUrl') + '/organizations', tenantId, username)
    return (
      <Link href={organizationLink.toString()} target="_blank">
        {t(LocaleKeys.manageOrganizationsLinkText)}
      </Link>
    );
  }, [tenantId, username, t]);

  return (
    <Shimmer
      customElementsGroup={getSuiteHeaderShimmerElements()}
      isDataLoaded={!isLoadingOrganizations}
      shimmerColors={SHIMMER_COLORS}
      styles={{ root: { zIndex: 6 } }} // TODO: Remove Skip to main content button in left nav to avoid using zIndex here
    >
      <SuiteHeader
        appId={clientId}
        userData={userData}
        searchBox={() => <ConnectedCustomSearchBox t={t} />}
        workloadId={MY_ACCESS_WORKLOAD_ID}
        navbarData={navBarData as NavBarData}
        culture={getCultureFromClaims(userData.rawClaims)}
        localizationMap={localizationMap}
        imageUrl={tenantBrandingUrl}
        appName={t(LocaleKeys.myAccess)}
        onClickAppBranding={handleAppBrandingClick}
        enabledApps={getMenuItems(appsMap, true, tenantId, username)}
        organizations={{
          value: organizations,
          isLoading: isLoadingOrganizations
        }}
        changeTenant={changeTenant}
        onSwitchTo={switchTo}
        onSwitch={switchAccount}
        hasError={hasError || headerOrganizationError !== null}
        helpLink={helpLink}
        onRenderComplete={handleHeaderRender}
        onHeaderButtonClicked={handleHeaderButtonClick}
        onLeavePreview={onLeavePreview()}
        isUniversalMeControlEnabled={isUniversalMeControlEnabled}
        evoBase={evoBase}
        viewAccountLink={viewAccountLink}
        onSignOut={logOut}
        manageTenantControl={manageOrganizationControl}
      />
    </Shimmer>
  );
};
