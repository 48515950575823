import { AccessReviewPartnerGuid } from '../models/AccessReviews/AccessReviewType';
import { ISubmitDecision } from '../models/AccessReviews/ISubmitDecision';
import { IFilter } from '../models/IFilter';
import { isEmptyOrUndefined } from './isEmptyOrUndefined';
import { findGuid } from './isGuid';
import { settingsManager } from './settings';
import { getTenantInfo } from './urlHelper';

const recommendationId = '13f63a0a-5b48-461e-a4b5-1de990b3eb18';
const groupByString = `?$apply=groupby((principal/schemaId,principal/id,principal/displayName))`;

export const getAccessReviewsBaseUrlBasedOnEnv = (): string => {
  return settingsManager.get('accessReviewsApiUrl')!;
};

export const getAccessReviewsApiUrl = (
  partnerId: string,
  isByod?: boolean,
  searchTerm?: string,
  orderBy?: string,
  isAscending?: boolean
): string => {
  let orderByString = '';
  if (!isEmptyOrUndefined(orderBy)) {
    orderByString = `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  }

  let filterString = ``;
  if (partnerId === AccessReviewPartnerGuid.Governance || partnerId === AccessReviewPartnerGuid.UserAccess) {
    if (isByod) {
      filterString = `(customDataProvider ne null)`;
    } else {
      filterString = `(customDataProvider eq null)`;
    }
  }

  if (!isEmptyOrUndefined(searchTerm)) {
    if (filterString !== ``) {
      filterString += ` and `;
    }
    const adjustedSearch = searchTerm.replace(/'/g, "''");
    filterString += `(contains(displayName,'${adjustedSearch}'))`;
  }

  if (filterString !== ``) {
    filterString = `&$filter=${filterString}`;
  }

  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(false);
  return (
    `${baseUrl}approvalWorkflowProviders/${partnerId}/requestsAwaitingMyDecision` +
    `?$select=decisionsCriteria,id,displayName,endDateTime,status,settings,businessFlowId,reviewedEntity,customDataProvider` +
    `&$expand=businessFlow&$top=10&$skip=0` +
    `${orderByString}${filterString}${tenantOptions}`
  );
};

export const getAccessReviewDecisionsApiUrl = (
  typeId?: string,
  secondaryId?: string,
  searchTerm?: string,
  orderBy?: string,
  isAscending?: boolean,
  filterContext?: IFilter
): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(false);
  const reviewId = findGuid(location.href);

  // if secondaryId is provided, this will filter on decisions based on that principal (used for user-centric)
  let filter = getDecisionAndRecommendationFilter(secondaryId, filterContext);

  if (searchTerm) {
    const adjSearch = searchTerm.replace(/'/g, "''");
    const searchFilter = `(contains(tolower(userDisplayName),'${adjSearch}') or contains(tolower(userPrincipalName),'${adjSearch}'))`;
    if (filter === '') {
      filter += `&$filter=${searchFilter}`;
    } else {
      filter += ` and ${searchFilter}`;
    }
  }

  let orderByString = '';
  if (isEmptyOrUndefined(orderBy)) {
    orderByString = `&$orderby=userDisplayName asc`;
  } else {
    orderByString = `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  }

  let applyString = '';
  if (typeId === 'user-centric') {
    applyString =
      `?$apply=groupby((principal/schemaId,principal/id,principal/displayName))` +
      `&$orderby=principal/displayName asc&$skip=0&$top=100`;
  } else {
    applyString = `?$top=100&$expand=insights&$skip=0${orderByString}${filter}${tenantOptions}`;
  }

  return (
    `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.Governance}/requests/${reviewId}/myDecisions` +
    applyString
  );
};

export const getSecondaryDecisionsApiUrl = (
  secondaryId?: string,
  searchTerm?: string,
  orderBy?: string,
  isAscending?: boolean,
  filterContext?: IFilter
): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(false);
  const reviewId = findGuid(location.href);

  // if secondaryId is provided, this will filter on decisions based on that principal (used for user-centric)
  let filter = getDecisionAndRecommendationFilter(secondaryId, filterContext);

  if (searchTerm) {
    const adjSearch = searchTerm.replace(/'/g, "''");
    const searchFilter = `(contains(tolower(userDisplayName),'${adjSearch}') or contains(tolower(userPrincipalName),'${adjSearch}'))`;
    if (filter === '') {
      filter += `&$filter=${searchFilter}`;
    } else {
      filter += ` and ${searchFilter}`;
    }
  }

  let orderByString = '';
  if (isEmptyOrUndefined(orderBy)) {
    orderByString = `&$orderby=userDisplayName asc`;
  } else {
    orderByString = `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  }

  return (
    `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.Governance}/requests/${reviewId}/myDecisions` +
    `?$top=100&$expand=insights&$skip=0${orderByString}${filter}${tenantOptions}`
  );
};

export const getReviewDetailsApiUrl = (): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(true);
  const reviewId = findGuid(location.href);
  return `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.Governance}/requests/${reviewId}${tenantOptions}`;
};

export const getDecisionsCriteriaApiUrl = (): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(true);
  const reviewId = findGuid(location.href);
  return (
    `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.AccessPackage}/requests/${reviewId}` +
    `/decisionsCriteria${tenantOptions}`
  );
};

export const getDecisionsSummaryApiUrl = (id: string): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(true);
  return `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.Governance}/requests/${id}/myDecisionsSummary${tenantOptions}`;
};

export const getDecisionHistoryApiUrl = (reviewId: string, decisionId: string): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(false);
  const expandHistories = '?$expand=histories($orderby=stage)';
  return (
    `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.Governance}/requests/${reviewId}` +
    `/decisions/${decisionId}${expandHistories}${tenantOptions}`
  );
};

export const submitReviewDecisionApiUrl = (decisionList: ISubmitDecision[], acceptRec?: boolean): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(true);
  const reviewId = findGuid(location.href);
  if (decisionList.length > 1 || acceptRec) {
    // Create CSV string of decision IDs
    let idList = '(';
    for (let i = 0; i < decisionList.length; i++) {
      idList = idList + (`\'` + decisionList[i].decisionId + `\'`);
      if (i < decisionList.length - 1) {
        idList = idList + ',';
      }
    }
    idList = idList + ')';

    if (acceptRec) {
      // batch URL (multiple decisions)
      return (
        `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.Governance}/requests/${reviewId}` +
        `/acceptRecommendations?$filter=(id in ${idList})`
      );
    } else {
      // batch URL (multiple decisions)
      return (
        `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.Governance}/requests/${reviewId}` +
        `/myDecisions/batchRecordDecisions?$filter=(id in ${idList}` +
        ` and reviewResult in ('Approve','Deny','DontKnow','NotReviewed'))`
      );
    }
  } else {
    return (
      `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.Governance}/requests/${reviewId}` +
      `/decisions/${decisionList[0].decisionId!}${tenantOptions}`
    );
  }
};

export const submitReviewAllDecisionApiUrl = (filterContext: IFilter, isSupervisorCentric?: boolean): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const reviewId = findGuid(location.href);
  const filter = getDecisionAndRecommendationFilter('', filterContext);
  return (
    `${baseUrl}approvalWorkflowProviders/${
      isSupervisorCentric ? AccessReviewPartnerGuid.UserAccess : AccessReviewPartnerGuid.Governance
    }/requests/${reviewId}` + `/myDecisions/batchRecordDecisions?${filter}`
  );
};

export const acceptAllRecommendationsApiUrl = (filterContext: IFilter, partnerId: string): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(true);
  const reviewId = findGuid(location.href);
  const filter = getDecisionAndRecommendationFilter('', filterContext);
  return `${baseUrl}approvalWorkflowProviders/${partnerId}/requests/${reviewId}/acceptRecommendations?${filter}&${tenantOptions}`;
};

export const getReviewerDisplayNamesUrl = (): string => {
  const baseUrl = settingsManager.get('msGraphResourceName')!;
  return `${baseUrl}/v1.0/directoryObjects/getByIds?$select=id,displayName`;
};

export const getEmergencyRaeStatusUrl = (partnerId: string): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  return `${baseUrl}approvalWorkflowProviders/${partnerId}/featureFlags`;
};

export const getDecisionAndRecommendationFilter = (secondaryId?: string, filterContext?: IFilter): string => {
  let filter = '';

  if (secondaryId && secondaryId.length > 0) {
    // commenting out until official support of secondary decisions
    // filter = `&$filter=(principal/id eq '${secondaryId}')`;
  }

  if (filterContext) {
    if (filterContext.selectedRecommendations!.length > 0 || filterContext.selectedDecisions!.length > 0) {
      // Filter string for each recommendation type being filtered on
      let recommendationFilter = '';
      if (filterContext.selectedRecommendations) {
        filterContext.selectedRecommendations.forEach((recommendation: string) => {
          if (recommendationFilter !== '') {
            recommendationFilter += ' or ';
          }
          recommendationFilter += `accessRecommendation eq %27${recommendation}%27`;
        });
      }

      // Filter string for each decision type being filtered on
      let decisionFilter = '';
      if (filterContext.selectedDecisions) {
        filterContext.selectedDecisions.forEach((decision: string) => {
          if (decisionFilter !== '') {
            decisionFilter += ' or ';
          }
          decisionFilter += `reviewResult eq %27${decision}%27`;
        });
      }

      // Add the recommendation filter query
      if (recommendationFilter !== '') {
        filter += `(${recommendationFilter})`;
      }

      // Add the decision type filter query
      if (decisionFilter !== '') {
        // Put an 'and' between recommendation and decision (if necessary)
        if (recommendationFilter !== '') {
          filter += ' and ';
        }
        filter += `(${decisionFilter})`;
      }

      // Put parentheses around the whole query if needed
      if (recommendationFilter !== '' && decisionFilter !== '') {
        filter = `(${filter})`;
      }

      filter = `&$filter=${filter}`;
    }
  }
  return filter;
};

/**
 * Returns the overview get reviews count API
 * @param partnerId - AccessReviewPartnerGuid
 * @returns The url of get review count
 */
export const getAccessReviewsCountApiUrl = (partnerId: string): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(false);
  return (
    `${baseUrl}approvalWorkflowProviders/${partnerId}/requestsAwaitingMyDecision?$count=true&$top=0` +
    `${tenantOptions}`
  );
};

/**
 * Returns the API URL for retrieving supervisor-centric access review decisions.
 * The URL includes the base URL, access review partner GUID, review ID, and tenant options.
 *
 * @returns The API URL for supervisor-centric access review decisions.
 */
export const getSupervisorCentricAccessReviewDecisionsApiUrl = (orderBy?: string, isAscending?: boolean): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(false);
  const reviewId = findGuid(location.href);

  let orderByString = '';
  if (isEmptyOrUndefined(orderBy)) {
    orderByString = `&$orderby=principal/displayName asc`;
  } else {
    orderByString = `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  }

  let countAndPageString = `&$top=100&$skip=0&$count=true`;

  return `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.UserAccess}/requests/${reviewId}/myDecisions/${groupByString}${orderByString}${countAndPageString}${tenantOptions}`;
};

/**
 * Returns the API URL for retrieving supervisor-centric access review decision principal resources.
 * This is the resources that the principal has been assigned to.
 *
 * @param id - The ID of the principal.
 * @returns The API URL for retrieving supervisor-centric access review decision principal resources.
 */
export const getSupervisorCentricAccessReviewDecisionPrincipalResourcesApiUrl = (id: string): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(false);
  const reviewId = findGuid(location.href);
  return `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.UserAccess}/requests/${reviewId}/myDecisions/?$filter=(principal/id eq '${id}')${tenantOptions}`;
};

/**
 * Returns the API URL for filtering on supervisor centric principals,
 * usually by filtering on the principal's display name.
 *
 * @param id - The ID of the principal.
 * @returns The API URL for retrieving supervisor-centric access review decision principal resources.
 */
export const getFilteredSupervisorCentricAccessReviewDecisionsApiUrl = (
  searchTerm?: string,
  filterContext?: IFilter,
  orderBy?: string,
  isAscending?: boolean
): string => {
  const baseUrl = getAccessReviewsBaseUrlBasedOnEnv();
  const tenantOptions = getTenantInfo(false);
  const reviewId = findGuid(location.href);

  let filter = '';
  if (filterContext) {
    filter = getDecisionAndRecommendationFilter('', filterContext);
  }

  if (searchTerm) {
    const adjSearch = searchTerm.replace(/'/g, "''");
    const searchFilter = `(contains(tolower(principal/displayName),'${adjSearch}'))`;
    if (filter === '') {
      filter += `&$filter=${searchFilter}`;
    } else {
      filter += ` and ${searchFilter}`;
    }
  }

  let orderByString = '';
  if (isEmptyOrUndefined(orderBy)) {
    orderByString = `&$orderby=principal/displayName asc`;
  } else {
    orderByString = `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  }

  let countAndPageString = `&$top=100&$skip=0&$count=true`;

  return `${baseUrl}approvalWorkflowProviders/${AccessReviewPartnerGuid.UserAccess}/requests/${reviewId}/myDecisions/${groupByString}${filter}${orderByString}${countAndPageString}${tenantOptions}`;
};
