import { css, IconButton, Link, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { QosProvider } from '@iamexperiences/ecos-telemetry';
import React from 'react';
import { Trans } from 'react-i18next'

import { IGrantRequestTargetType } from '../../../../models';
import { AnswerString } from '../../../../models/ELM/AnswerString';
import { IGrantPolicy } from '../../../../models/ELM/IGrantPolicy';
import { IVerifiableCredentialStatusType } from '../../../../models/ELM/IVerifiableCredentialStatus';
import { QuestionType } from '../../../../models/ELM/QuestionType';
import { isEmptyOrUndefined, isInternalUser } from '../../../../shared';
import { LocaleKeys } from '../../../../shared/LocaleKeys';
import { Routes } from '../../../../shared/Routes';
import { getInlineSpinner } from '../../../../shared/spinner';
import { ResourceView } from '../../../Shared/ResourceView';
import { StyledModal } from '../../../Shared/StyledModal';
import { onRenderValidationErrors } from '../../AddGrantRequest/GrantRequestShared';
import { ConnectedConsentForm } from '../ConsentForm';
import { ConnectedGetPolicySelection } from '../GetPolicySelection';
import { ConnectedGrantRequestQuestions } from '../GrantRequestQuestions';
import { IRequestGrantPanelProps, IRequestGrantPanelState, RequestGrantState } from '../RequestGrantPanel.types';
import { ConnectedVerifyCredentialPresentation, STATUS_POLL_INTERVAL } from '../VerifyCredentialPresentation';

const styles = require('./RequestGrantModal.scoped.scss');

const requestGrantStateMap: Record<RequestGrantState, LocaleKeys> = {
  [RequestGrantState.ResourceView]: LocaleKeys.resourceView,
  [RequestGrantState.SelectPolicy]: LocaleKeys.selectPolicy,
  [RequestGrantState.Questions]: LocaleKeys.additionalQuestions,
  [RequestGrantState.ConsentForm]: LocaleKeys.consentForm,
  [RequestGrantState.VerifiableCredential]: LocaleKeys.presentVerifiedId
};

const verifiedLearnURL = "https://learn.microsoft.com/en-us/azure/active-directory/verifiable-credentials/decentralized-identifier-overview"

export class RequestGrantModal extends React.Component<IRequestGrantPanelProps, IRequestGrantPanelState> {
  private get hasError(): boolean {
    return this.props.validationErrors?.length > 0;
  }

  private get isValid(): boolean {
    const {
      grantRequest,
      needsConsent,
      notRequestableForMyself,
      policy,
      policiesLoading,
      grantTargetType,
      validationErrors,
      oboChecked,
      grantTarget
    } = this.props;
    if (!grantRequest) {
      return false;
    }

    switch (this.state.requestState) {
      case RequestGrantState.ResourceView: {
        if (grantTargetType === IGrantRequestTargetType.OnBehalfOf) {
          return !policiesLoading && validationErrors?.length === 0 && grantTarget !== undefined;
        }
        return !policiesLoading && oboChecked && !notRequestableForMyself && !this.hasError;
      }
      case RequestGrantState.SelectPolicy: {
        return policy !== undefined;
      }
      case RequestGrantState.Questions: {
        return policy !== undefined && this.areQuestionsAnswered(policy) && this.isJustificationAndScheduleOkay(policy);
      }
      case RequestGrantState.ConsentForm: {
        return needsConsent && this.state.consented;
      }
      default: {
        return false;
      }
    }
  }

  constructor(ownProps: IRequestGrantPanelProps) {
    super(ownProps);
    this.state = {
      startTimeUnixEpoch: Date.now(),
      requestState: RequestGrantState.ResourceView,
      consented: false
    };

    const { policiesDefined, policiesLoading, getRequestPolicies, grantRequest, policy, noPolicyOptions } = ownProps;

    if (!policiesDefined && !policiesLoading && !this.hasError){
      getRequestPolicies(grantRequest!)
    }

    if (policy !== undefined && noPolicyOptions) {
      this.onNext(RequestGrantState.SelectPolicy, policy)();
    }
  }

  public componentDidUpdate(prevProps: IRequestGrantPanelProps): void {
    const { policy, noPolicyOptions, policiesLoading, grantRequest, oboChecked, getHasOBOPolicies, getRequestPolicies, notRequestableForMyself, grantTargetType } = this.props;
    if (
      this.state.requestState === RequestGrantState.SelectPolicy &&
      policy !== undefined &&
      noPolicyOptions
    ) {
      this.onNext(RequestGrantState.SelectPolicy, policy)();
    }
    if (notRequestableForMyself && !oboChecked && !policiesLoading) {
      // Call applicable policy api for 2nd try for obo, as resource has been loaded
      getHasOBOPolicies(grantRequest!)
    }
    else if (!policiesLoading && grantTargetType === IGrantRequestTargetType.Myself && prevProps.grantTargetType === IGrantRequestTargetType.OnBehalfOf) {
      getRequestPolicies(grantRequest!)
    }
  }

  public componentWillUnmount(): void {
    if (this.state.verifiableCredentialIntervalHandle) {
      clearInterval(this.state.verifiableCredentialIntervalHandle);
    }
    this.props.clearValidationErrors();
  }

  public render(): JSX.Element {
    if (!this) {
      return <div />;
    }
    const inVerifiedCredentials = this.state.requestState === RequestGrantState.VerifiableCredential;
    const { onDismiss } = this.props;

    return (
      <QosProvider name="RequestGrantModal">
        <StyledModal
          isOpen
          onDismiss={onDismiss}
        >
          <div className={css(styles.modalInfoWrapper)}>
            {this.renderHeader()}
            {this.renderStatusBox()}
            {this.renderBody()}
            {!inVerifiedCredentials && this.renderFooter()}
          </div>
        </StyledModal>
      </QosProvider>
    );
  }

  private isLoading(): boolean {
    const {
      policiesLoading,
      grantTargetType,
      oboChecked,
      loadableDirectReports
    } = this.props;
    // VC screen constantly polls the policy requirements to see if the VC has been verified
    if (this.state.requestState === RequestGrantState.VerifiableCredential) {
      return false;
    }
    // If we are in any other view it could be that policies have not been loaded
    if (this.state.requestState !== RequestGrantState.ResourceView) {
      return policiesLoading;
    }

    // if user is in OBO mode we get policies for the target user but don't need the spinner
    if (grantTargetType === IGrantRequestTargetType.OnBehalfOf) {
      return false;
    }

    // Otherwise show spinner if policies are loading or we haven't checked this AP for OBO enabled or we are loading the direct reports
    return policiesLoading || !oboChecked || loadableDirectReports?.isLoading;
  }

  private renderBody(): JSX.Element {
    let content: JSX.Element | null = null;
    const {
      policy,
      entitlementResources,
      entitlementDescription,
      canViewResources,
      entitlementUrl,
      oboAllowed,
      showCopyLinkNotification,
      getRequestPolicies,
      isResourceLoaded: isResourceLoaded,
      entitlementId
    } = this.props;

    if (this.isLoading()) {
      return getInlineSpinner();
    }

    switch (this.state.requestState) {
      case RequestGrantState.ResourceView: {
        content = (
          <ResourceView
            entitlementResources={entitlementResources}
            entitlementDescription={entitlementDescription}
            canViewResources={isResourceLoaded? canViewResources: isInternalUser()}
            entitlementUrl={entitlementUrl}
            oboAllowed={oboAllowed && this.props.loadableDirectReports.value.length > 0}
            showCopyLinkNotification={showCopyLinkNotification}
            isResourceLoaded={isResourceLoaded?? true}
            entitlementId={entitlementId?? ''}
          />
        );
        break;
      }
      case RequestGrantState.SelectPolicy: {
        content = <ConnectedGetPolicySelection usingModal getRequestPolicies={getRequestPolicies} />;
        break;
      }
      case RequestGrantState.VerifiableCredential: {
        content = <ConnectedVerifyCredentialPresentation usingModal />;
        break;
      }
      case RequestGrantState.Questions: {
        content = <ConnectedGrantRequestQuestions policy={policy} />;
        break;
      }
      case RequestGrantState.ConsentForm: {
        content = <ConnectedConsentForm onToggleConsent={this.onToggleConsent} />;
        break;
      }
    }
    return <div className={css(styles.content)}>{content}</div>;
  }

  private readonly onToggleConsent = (_ev?: React.FormEvent<HTMLElement>, isChecked?: boolean): void => {
    this.setState({
      ...this.state,
      consented: isChecked!
    });
  };

  private nextText(): LocaleKeys {
    const { needsConsent } = this.props;
    const isInSubmitStep =
      (needsConsent && this.state.requestState === RequestGrantState.ConsentForm) ||
      (!needsConsent && this.state.requestState === RequestGrantState.Questions);
    if (isInSubmitStep) {
      return LocaleKeys.submitRequest;
    } else {
      return LocaleKeys.continueStep;
    }
  }

  private renderHeader(): JSX.Element {
    const { t, onDismiss, entitlementName } = this.props;
    const title = this.state.requestState === RequestGrantState.ResourceView
      ? entitlementName
      : t(requestGrantStateMap[this.state.requestState]);
    return (
      <div className={css(styles.modalInfoHeader)}>
        <div className={css(styles.modalTitle)}>
          {this.showBackButton() && (
            <IconButton
              className={css(styles.backButton)}
              iconProps={{ iconName: 'Back' }}
              ariaLabel={t(LocaleKeys.back)}
              onClick={this.onBack}
            />
          )}
          <h3 title={title}>
            {title}
          </h3>
        </div>

        <IconButton
          className={css(styles.closeButton)}
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel={t(LocaleKeys.cancel)}
          onClick={onDismiss}
        />
      </div>
    );
  }

  private renderStatusBox(): JSX.Element {
    const { t, entitlementName, policiesContainVerifiableCredentials, validationErrors, grantTarget, grantTargetType } = this.props;
    const showVcWarning =
      this.state.requestState === RequestGrantState.ResourceView && policiesContainVerifiableCredentials;
    const showVcSuccess =
      this.state.requestState === RequestGrantState.Questions &&
      this.props.policy?.verifiableCredentialRequirementStatus?.['@odata.type'] ===
      IVerifiableCredentialStatusType.verified;
    const showOBOBanner = this.state.requestState === RequestGrantState.Questions && grantTarget;
    const showValidationErrors = this.hasError && grantTargetType !== IGrantRequestTargetType.OnBehalfOf

    if (!showVcWarning && !showVcSuccess && !showValidationErrors && !showOBOBanner) {
      return <></>;
    }

    const grantTargetName = grantTarget?.displayName;
    const grantTargetEmail = grantTarget?.email ? ` (${grantTarget.email})` : '';

    return (
      <div className={styles.statusBox}>
        {showVcWarning && (
          <MessageBar isMultiline messageBarType={MessageBarType.warning}>
            {t(LocaleKeys.verifiedLearnMore)}
            <Link href={verifiedLearnURL}>
              {t(LocaleKeys.microsoftVerifiedIDs)}
            </Link>
            .
          </MessageBar>
        )}
        {onRenderValidationErrors(entitlementName!, validationErrors, this.onExistingGrantLinkClicked, t, true)}
        {showVcSuccess && (
          <MessageBar isMultiline messageBarType={MessageBarType.success}>
            {t(LocaleKeys.verifiableCredentialShared)}
          </MessageBar>
        )}
        {showOBOBanner && (
          <MessageBar isMultiline messageBarType={MessageBarType.info} className={css(styles.oboMessageBar)}>
            <Trans
              i18nKey={LocaleKeys.requestingOBOMessage}
              values={{ grantTargetName, grantTargetEmail }}
              components={[<span key={grantTargetName} className={css(styles.name)}>{grantTargetName}</span>]}
            />
          </MessageBar>
        )}
      </div>
    );
  }

  private renderFooter(): JSX.Element {
    if (!this) {
      return <div />;
    }
    const { submitting, t } = this.props;
    const leftText = t(this.nextText());

    const onNext = this.onNext(this.state.requestState, this.props.policy);

    return (
      <div className={css(styles.footer)}>
        <PrimaryButton
          ariaLabel={leftText}
          text={leftText}
          onClick={onNext}
          disabled={!this.isValid || submitting || this.isLoading()}
          className={css(styles.primaryFooterButton)}
        />
      </div>
    );
  }

  private isJustificationAndScheduleOkay(policy: IGrantPolicy): boolean {
    if (
      policy.isRequestorJustificationRequired &&
      (this.props.grantRequest?.justification === undefined || this.props.grantRequest.justification.trim() === '')
    ) {
      return false;
    }
    if (policy.isCustomAssignmentScheduleAllowed) {
      const startDateTime = this.props.grantRequest?.accessPackageAssignment?.schedule?.startDateTime;
      const endDateTime = this.props.grantRequest?.accessPackageAssignment?.schedule?.stopDateTime;
      if (startDateTime !== undefined && startDateTime !== null && endDateTime !== undefined && endDateTime !== null) {
        // both dates are defined
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        // start date is before today
        if (startDateTime < today) {
          return false;
        }
        // end date is beyond expiration
        if (
          !isEmptyOrUndefined(policy.expirationDate) &&
          policy.expirationDate < endDateTime
        ) {
          return false;
        }
        // start date is after end date
        if (endDateTime < startDateTime) {
          return false;
        }
      } else if (
        (startDateTime !== undefined && startDateTime !== null) !== (endDateTime !== undefined && endDateTime !== null)
      ) {
        // one is defined
        return false;
      }
    }
    return true;
  }

  private areQuestionsAnswered(policy: IGrantPolicy): boolean {
    if (policy.questions.length > 0) {
      const answers = this.props.grantRequest?.answers;
      let isValid = true;
      policy.questions.forEach((qa) => {
        if (!isValid) {
          return;
        }
        const matchAnswers = answers?.filter((a) => a.answeredQuestion.id === qa.id);
        if (matchAnswers === undefined || matchAnswers === null || matchAnswers.length === 0) {
          isValid = false;
          return;
        }
        const answer = matchAnswers[0] as AnswerString;
        if (qa.isRequired && isEmptyOrUndefined(answer.value)) {
          isValid = false;
          return;
        }
        if (
          qa.$type === QuestionType.TextInputQuestion &&
          !isEmptyOrUndefined(qa.regexPattern) &&
          !isEmptyOrUndefined(answer.value)
        ) {
          const regex = new RegExp(qa.regexPattern);
          isValid = regex.test(qa.answerValue);
        }
      });
      return isValid;
    }
    return true;
  }

  // background interval checks on VC STATUS_POLL_INTERVAL
  private checkForVcProgression(): void {
    const credentialStatus = this.props.policy?.verifiableCredentialRequirementStatus;
    if (credentialStatus) {
      switch (credentialStatus['@odata.type']) {
        case IVerifiableCredentialStatusType.required:
        case IVerifiableCredentialStatusType.retrieved: {
          if (this.state.requestState !== RequestGrantState.VerifiableCredential) {
            let verifiableCredentialIntervalHandle = this.state.verifiableCredentialIntervalHandle;
            if (!verifiableCredentialIntervalHandle) {
              verifiableCredentialIntervalHandle = setInterval(
                this.checkForVcProgression.bind(this),
                STATUS_POLL_INTERVAL
              );
            }
            this.setState({
              ...this.state,
              requestState: RequestGrantState.VerifiableCredential,
              verifiableCredentialIntervalHandle
            });
          }
          break;
        }
        case IVerifiableCredentialStatusType.verified: {
          if (this.state.requestState !== RequestGrantState.Questions) {
            if (this.state.verifiableCredentialIntervalHandle) {
              clearInterval(this.state.verifiableCredentialIntervalHandle);
            }
            this.setState({
              ...this.state,
              requestState: RequestGrantState.Questions,
              verifiableCredentialIntervalHandle: undefined
            });
          }
          break;
        }
      }
    }
  }

  private onNext(currentStep: RequestGrantState, policy?: IGrantPolicy): () => void {
    const { needsConsent } = this.props;
    return () => {
      switch (currentStep) {
        case RequestGrantState.ResourceView: {
          this.setState({
            ...this.state,
            requestState: RequestGrantState.SelectPolicy
          });
          break;
        }
        case RequestGrantState.SelectPolicy: {
          let verifiableCredentialIntervalHandle = this.state.verifiableCredentialIntervalHandle;
          let requestState = RequestGrantState.Questions;
          if (
            policy?.verifiableCredentialRequirementStatus &&
            policy.verifiableCredentialRequirementStatus['@odata.type'] !== IVerifiableCredentialStatusType.verified
          ) {
            requestState = RequestGrantState.VerifiableCredential;
            if (!verifiableCredentialIntervalHandle) {
              verifiableCredentialIntervalHandle = setInterval(
                this.checkForVcProgression.bind(this),
                STATUS_POLL_INTERVAL
              );
            }
          }
          this.setState({
            ...this.state,
            requestState,
            verifiableCredentialIntervalHandle
          });
          break;
        }
        case RequestGrantState.VerifiableCredential: {
          if (this.state.verifiableCredentialIntervalHandle) {
            clearInterval(this.state.verifiableCredentialIntervalHandle);
          }
          this.setState({
            ...this.state,
            requestState: RequestGrantState.Questions,
            verifiableCredentialIntervalHandle: undefined
          });
          break;
        }
        case RequestGrantState.Questions: {
          if (needsConsent) {
            this.setState({
              ...this.state,
              requestState: RequestGrantState.ConsentForm,
              verifiableCredentialIntervalHandle: undefined
            });
          } else {
            // submit!
            this.props.postGrantRequest({
              newGrantRequest: {
                ...this.props.grantRequest!
              },
              entitlementName: this.props.entitlementName!
            });
            this.logVCTelemetry();
            this.props.onSubmitRequest();
          }
          break;
        }
        case RequestGrantState.ConsentForm: {
          // submit!
          this.props.postGrantRequest({
            newGrantRequest: {
              ...this.props.grantRequest!
            },
            entitlementName: this.props.entitlementName!
          });
          this.logVCTelemetry();
          this.props.onSubmitRequest();
        }
      }
    };
  }

  private readonly logVCTelemetry = (): void => {
    const elapsedTime = Math.round((Date.now() - this.state.startTimeUnixEpoch) / 1000);
    // log telemetry for VCs used
    const usedVerifiableCredential =
      this.props.policy?.verifiableCredentialRequirementStatus !== undefined &&
      this.props.policy?.verifiableCredentialRequirementStatus !== null;
    this.props.recordGrantRequestDetailedEvent(elapsedTime, usedVerifiableCredential);
  };

  private readonly showBackButton = (): boolean => {
    // if we are in the new request form, show the back button and title only if not on resource view
    return this.state.requestState !== RequestGrantState.ResourceView;
  };

  private readonly onBack = (): void => {
    const { requestState, verifiableCredentialIntervalHandle } = this.state;

    // VC auto-forwards, so all backs should just go to select policy, except when in modal view
    if (verifiableCredentialIntervalHandle) {
      clearInterval(verifiableCredentialIntervalHandle);
    }

    let prevState = RequestGrantState.SelectPolicy;
    // If the user is in the SelectPolicy state or if no policies are available, then we should show the ResourceView.
    if (requestState === RequestGrantState.SelectPolicy || this.props.noPolicyOptions) {
      prevState = RequestGrantState.ResourceView;
    }

    this.setState({
      ...this.state,
      requestState: prevState,
      verifiableCredentialIntervalHandle: undefined
    });
  };

  private readonly onExistingGrantLinkClicked = (guid: string): void => {
    this.props.navigateTo(`${Routes.accessIHaveTab}/${guid}`);
    this.props.onDismiss();
  };
}
